import React, { useEffect } from 'react'
import { Button, Col, Container, Image, Nav, Navbar, Row,Alert } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { heroImage, logo } from '../../assets'
import ReactGA from 'react-ga';

export default function HeroSection() {
    const navigate = useNavigate()

    const changeBackground = () => {
        if (window.scrollY >= 66) {
            document.getElementById('navbar-component').style.backgroundColor = '#FFF'
        } else {
            document.getElementById('navbar-component').style.backgroundColor = 'initial'
        }
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        changeBackground()
        window.addEventListener("scroll", changeBackground)
    })

    let gotoServices = () => {
        let element = document.getElementById('storage-service')
        let servicelink = document.getElementById('service-link')
        let processlink = document.getElementById('process-link')
        let chooseLink = document.getElementById('choose-link')
        servicelink.className = 'navbar-active-link navbar-link px-4'
        processlink.className = 'navbar-link px-4'
        chooseLink.className = 'navbar-link px-4'
        element.scrollIntoView(true)
        //         var scrolledY = window.scrollY;

        // if(scrolledY){
        //   window.scroll(0, scrolledY - 93);
        // }
    }
    let gotoProcess = () => {
        let element = document.getElementById('process-section')
        let servicelink = document.getElementById('service-link')
        let chooseLink = document.getElementById('choose-link')
        let processlink = document.getElementById('process-link')
        servicelink.className = 'navbar-link px-4'
        chooseLink.className = 'navbar-link px-4'
        processlink.className = 'navbar-active-link navbar-link px-4'
        element.scrollIntoView(true)
    }

    let gotoChoose = () => {
        let element = document.getElementById('about-us')
        let servicelink = document.getElementById('service-link')
        let processlink = document.getElementById('process-link')
        let chooseLink = document.getElementById('choose-link')
        servicelink.className = 'navbar-link px-4'
        processlink.className = 'navbar-link px-4'
        chooseLink.className = 'navbar-active-link navbar-link px-4'
        element.scrollIntoView(true)

    }

    let gotoContact = () => {
        let element = document.getElementById('contact-section')
        let servicelink = document.getElementById('service-link')
        let processlink = document.getElementById('process-link')
        servicelink.className = 'navbar-link px-4'
        processlink.className = 'navbar-link px-4'
        element.scrollIntoView(true)
    }

    return (
        <div className='position-relative hero-section'>
            <Navbar className='navbar-component' expand="lg" id='navbar-component'>
                <Container fluid className='navbar-container'>
                    <Navbar.Brand href="/">
                        <Image src={logo} className='logo' />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse className='justify-content-end' id="basic-navbar-nav">
                        <Nav className="m-0 align-items-lg-center align-items-end">
                            <Nav.Link id='service-link' className={`navbar-link px-4`} onClick={gotoServices}>Services</Nav.Link>
                            <Nav.Link id='process-link' className={`navbar-link px-4`} onClick={gotoProcess}>How it Works</Nav.Link>
                            <Nav.Link id='choose-link' className={`navbar-link px-4`} onClick={gotoChoose}>Why Choose Us</Nav.Link>
                            <Nav.Link id='payment-link' className={`navbar-link px-4`} onClick={() => navigate('blog-section')}>Blog</Nav.Link>
                            <Nav.Link id='payment-link' className={`navbar-link px-4`} onClick={() => navigate('payment-details')}>Payment</Nav.Link>
                            <Button className='mx-2 mt-lg-0 mb-lg-0 m-2 contact-button' onClick={() => navigate('contactus')}>Contact us</Button>
                            <Button className='sign-in-button m-lg-0 m-2' onClick={() => navigate('get-quote')}>Get a Quote</Button>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
                {/* <FormModalComponent open={ShowFormModal} close={() => setShowFormModal(false)} /> */}
            </Navbar>
            <Row className='mx-0 hero-section-details'>
                <Col className='d-flex flex-column justify-content-center'>
                    <h1 className='hero-title text-start mt-4' style={{ zIndex: 11 }}>
                        One of the best <br />
                        <span style={{ color: 'var(--primary-color)', fontSize: '1.15em' }}> Self Storage Service</span> <br />
                        provider in and out of <br />
                        Bangalore. <br />
                    </h1>
                   
                    <div className='d-flex justify-content-start mt-2'>
                        <Button className='landing-page-button w-auto px-3' onClick={() => navigate('get-quote')}>Get a Free Quote</Button>
                    </div>
                </Col>
                <Col className='d-flex justify-content-end'>
                    <Image className='hero-image' src={heroImage} alt='Best Storage Service in Bangalore' />
                </Col>
            </Row>
            {/* </div> */}
        </div>
    )
}

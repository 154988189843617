import React from 'react'
import { Image, Row } from 'react-bootstrap'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { deliverIcon, monitorIcon, packIcon, pickIcon, storeIcon } from '../../assets'

export default function HowItWorkSection() {
    return (
        <div className='how-it-work-section' id='process-section'>
            <h2 style={{ fontWeight: '800', fontSize: '2em' }}>How it Works</h2>
            <div className='w-100 d-flex justify-content-center'>
                <AnimationOnScroll duration={1} animateIn="animate__zoomIn" style={{ borderTop: '3px solid #000', width: '30%' }}>
                </AnimationOnScroll>
            </div>
            <p className='mb-0 mt-5' style={{ fontWeight: '500' }}>Right from packing to stowing and delivering back when you need it.</p>
            <p style={{ fontWeight: '500', marginBottom: '72px' }}>Your stored items stay safe and insured with us</p>
            <Row className='mx-0 justify-content-between align-items-stretch h-auto'>
                <AnimationOnScroll duration={2} animateIn="animate__slideInUp" style={{ flex: '1' }} className='w-auto d-flex flex-column'>
                    <div style={{ height: '212px' }} className='d-flex align-items-end justify-content-center'>
                        <Image src={packIcon} className='how-it-works-image-1 mb-2' />
                    </div>
                    <h3 style={{ fontWeight: '700' }}>01</h3>
                    <p className='how-it-work-para-1 w-100'>We pack at your <br />home</p>
                </AnimationOnScroll>
                
                <AnimationOnScroll duration={2} animateIn="animate__slideInUp" style={{ flex: '1' }} className='w-auto  d-flex flex-column'>
                    <div style={{ height: '212px' }} className='d-flex align-items-end justify-content-center'>
                        <Image src={pickIcon} className='how-it-works-image-2 mb-2' />
                    </div>
                    <h3 style={{ fontWeight: '700' }}>02</h3>
                    <p className='how-it-work-para-2 w-100'>We pick up from your<br /> door step</p>
                </AnimationOnScroll>
                <AnimationOnScroll duration={2} animateIn="animate__slideInUp" style={{ flex: '1' }} className='w-auto d-flex flex-column'>
                    <div style={{ height: '212px' }} className='d-flex align-items-end justify-content-center'>
                        <Image src={storeIcon} className='how-it-works-image-3 mb-2' />
                    </div>
                    <h3 style={{ fontWeight: '700' }}>03</h3>
                    <p className='how-it-work-para-3 w-100'>We store in our complete<br />pest free warehouse</p>
                </AnimationOnScroll>
                <AnimationOnScroll duration={2} animateIn="animate__slideInUp" style={{ flex: '1' }} className='w-auto d-flex flex-column'>
                    <div style={{ height: '212px' }} className='d-flex align-items-end justify-content-center'>
                        <Image src={monitorIcon} className='how-it-works-image-4 mb-2' />
                    </div>
                    <h3 style={{ fontWeight: '700' }}>04</h3>
                    <p className='how-it-work-para-4 w-100'> We store under 24*7<br /> cctv surveillance </p>
                </AnimationOnScroll>
                <AnimationOnScroll duration={2} animateIn="animate__slideInUp" style={{ flex: '1' }} className='w-auto d-flex flex-column'>
                    <div style={{ height: '212px' }} className='d-flex align-items-end justify-content-center'>
                        <Image src={deliverIcon} className='how-it-works-image-5 mb-2' />
                    </div>
                    <h3 style={{ fontWeight: '700' }}>05</h3>
                    <p className='how-it-work-para-5 w-100'>We deliver when you <br />need</p>
                </AnimationOnScroll>
                
            </Row>
            {/* <Row className='mx-0'>
                <Col className='col-md-12 col-5'>
                    <Row className='mx-0'>
                        <AnimationOnScroll duration={2} animateIn="animate__slideInUp" className='col-md-2 col-lg-auto col-12 d-flex mx-lg-3 mx-xl-2 mx-md-2 align-items-end mb-3 mb-md-0 how-it-work-step'>
                            <Image src={packIcon} className='how-it-works-image-1' />
                        </AnimationOnScroll>
                        <AnimationOnScroll duration={2} delay={200} animateIn="animate__slideInUp" className='col-md-2 col-lg-auto mx-lg-3 mx-xl-2 mx-md-2 col-12 d-flex align-items-end  mb-3 mb-md-0 how-it-work-step'>
                            <Image src={pickIcon} className='how-it-works-image-2' />
                        </AnimationOnScroll>
                        <AnimationOnScroll duration={2} delay={400} animateIn="animate__slideInUp" className='col-md-2 col-lg-auto col-12 mx-xl-2 mx-lg-3 mx-md-2  d-flex align-items mb-3 mb-md-0-end how-it-work-step'>
                            <Image src={storeIcon} className='how-it-works-image-3' />
                        </AnimationOnScroll>
                        <AnimationOnScroll duration={2} delay={600} animateIn="animate__slideInUp" className='col-md-2 col-lg-auto col-12 mx-xl-2 mx-lg-3 mx-md-2 d-flex align-items-end mb-3 mb-md-0 how-it-work-step'>
                            <Image src={monitorIcon} className='how-it-works-image-4' />
                        </AnimationOnScroll>

                        <AnimationOnScroll duration={2} delay={800} animateIn="animate__slideInUp" className='col-md-2 col-lg-auto col-12 mx-xl-2 mx-lg-3 mx-md-2 d-flex align-items-end mb-3 mb-md-0 how-it-work-step'>
                            <Image src={deliverIcon} className='how-it-works-image-5' />
                        </AnimationOnScroll>
                    </Row>
                </Col>
                <Col className='col-md-12 col-7'>
                    <Row className=' mx-0 mt-4'>
                        <AnimationOnScroll duration={2} animateIn="animate__slideInUp" className='col-12 col-md-2 col-lg-auto mb-3 mb-md-0 mx-md-2 mx-xl-1 mx-lg-0'>
                            <h3 style={{ fontWeight: '700' }}>01</h3>
                            <p className='how-it-work-para-1'>We pack at your <br />home</p>
                        </AnimationOnScroll>
                        <AnimationOnScroll duration={2} delay={200} animateIn="animate__slideInUp" className='col col-12 col-md-2 col-lg-auto mb-3 mb-md-0 mx-md-2 mx-xl-0 mx-lg-0'>
                            <h3 style={{ fontWeight: '700' }}>02</h3>
                            <p className='how-it-work-para-2'>We pick up from your<br /> door step</p>
                        </AnimationOnScroll>
                        <AnimationOnScroll duration={2} delay={400} animateIn="animate__slideInUp" className='col col-12 col-md-2 col-lg-auto mb-3 mb-md-0 mx-md-2 mx-xl-0 mx-lg-0'>
                            <h3 style={{ fontWeight: '700' }}>03</h3>
                            <p className='how-it-work-para-3'>We store in our <br />warehouse</p>
                        </AnimationOnScroll>
                        <AnimationOnScroll duration={2} delay={600} animateIn="animate__slideInUp" className='col col-12 col-md-2 col-lg-auto mb-3 mb-md-0 mx-md-2 mx-xl-0 mx-lg-0'>
                            <h3 style={{ fontWeight: '700' }}>04</h3>
                            <p className='how-it-work-para-4'> We store under 24*7<br /> cctv surveillance </p>
                        </AnimationOnScroll>
                        <AnimationOnScroll duration={2} delay={800} animateIn="animate__slideInUp" className='col col-12 col-md-2 col-lg-auto mx-md-2 mx-xl-0 mx-lg-0'>
                            <h3 style={{ fontWeight: '700' }}>05</h3>
                            <p className='how-it-work-para-5'>We deliver when you <br />need</p>
                        </AnimationOnScroll>
                    </Row>
                </Col>
            </Row> */}
        </div>
    )
}

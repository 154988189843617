import jsPDF from 'jspdf'
import React, { useEffect, useState } from 'react'
import { Button, Card, Form, Image, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { getCategoryWiseItems, getQuote } from '../../api'
import { logo, thankyouIcon } from '../../assets'
import './FormModalComponent.css'


let doc, LfurnitureRate, MfurnitureRate, SfurnitureRate, LapplianceRate, MapplianceRate, SapplianceRate, LboxRate,
    MboxRate, SboxRate, PackAndMoveRate, GST, offer, items = [], largeFurnitureItems, mediumfurnitureItems, smallFurnitureItems,
    largeApplianceItems, mediumApplianceItems, smallApplianceItems

export default function HouseholdQueries(props) {
    const navigate = useNavigate()
    const [MediumAppliance, setMediumAppliance] = useState('0')
    const [currentItem, setcurrentItem] = useState('furniture')
    const [Mediumfurniture, setMediumfurniture] = useState('0')
    const [Largefurniture, setLargefurniture] = useState('0')
    const [Smallfurniture, setSmallfurniture] = useState('0')
    const [LargeAppliance, setLargeAppliance] = useState('0')
    const [SmallAppliance, setSmallAppliance] = useState('0')
    const [liftAvailable, setLiftAvailable] = useState(null)
    const [submitting, setSubmitting] = useState(false)
    const [MediumBox, setMediumBox] = useState('0')
    const [Duration, setDuration] = useState(null)
    const [NeedHelp, setNeedHelp] = useState(null)
    const [LargeBox, setLargeBox] = useState('0')
    const [SmallBox, setSmallBox] = useState('0')
    const [Loading, setLoading] = useState(true)
    const [floor, setFloor] = useState(0)
    const [Time, setTime] = useState(null)

    useEffect(() => {
        Promise.all([getCategoryWiseItems(props?.category?.id)])
            .then(values => Promise.all(values.map((value) => value.json())))
            .then(res => {
                items = res[0]
                console.log(items)
                largeFurnitureItems = items.filter(item => item.title === 'Large Furniture')[0]
                mediumfurnitureItems = items.filter(item => item.title === 'Medium Furniture')[0]
                smallFurnitureItems = items.filter(item => item.title === 'Small Furniture')[0]
                largeApplianceItems = items.filter(item => item.title === 'Large appliances')[0]
                mediumApplianceItems = items.filter(item => item.title === 'Medium appliances')[0]
                smallApplianceItems = items.filter(item => item.title === 'Small appliances')[0]

                setLoading(false)
            })

        LfurnitureRate = props.rates.filter(item => item.title === 'Large Furniture')[0]?.rate;
        MfurnitureRate = props.rates.filter(item => item.title === 'Medium Furniture')[0]?.rate;
        SfurnitureRate = props.rates.filter(item => item.title === 'Small Furniture')[0]?.rate;
        LapplianceRate = props.rates.filter(item => item.title === 'Large appliances')[0]?.rate;
        MapplianceRate = props.rates.filter(item => item.title === 'Medium appliances')[0]?.rate;
        SapplianceRate = props.rates.filter(item => item.title === 'Small appliances')[0]?.rate;
        LboxRate = props.rates.filter(item => item.title === 'Large Boxes')[0]?.rate;
        MboxRate = props.rates.filter(item => item.title === 'Medium Boxes')[0]?.rate;
        SboxRate = props.rates.filter(item => item.title === 'Small Boxes')[0]?.rate;
        GST = props.rates.filter(item => item.title === 'GST')[0]?.rate
        offer = props.rates[props.rates.length - 1]?.offer_rate
    }, [props, Loading])

    let getEstimatedCharge = () => {
        setSubmitting(true)
        let storingSubtotal = Number(Largefurniture) * Number(LfurnitureRate) + Number(Mediumfurniture) * Number(MfurnitureRate) + Number(Smallfurniture) * Number(SfurnitureRate) +
            Number(LargeAppliance) * Number(LapplianceRate) + Number(MediumAppliance) * Number(MapplianceRate) + Number(SmallAppliance) * Number(SapplianceRate) + Number(LargeBox) * Number(LboxRate) +
            Number(MediumBox) * Number(MboxRate) + Number(SmallBox) * Number(SboxRate)

        let storingGST = Math.round(GST * storingSubtotal / 100)

        let storingTotal = storingSubtotal + storingGST

        if (NeedHelp === '1') {
            let packAndMoveRateRatio = props.rates.filter(rate => rate.title === 'Transportation Charge Ratio')[0]?.rate?.split(':')
            PackAndMoveRate = packAndMoveRateRatio[1] * (packAndMoveRateRatio[0] * storingSubtotal)
        }
        else PackAndMoveRate = 0

        let movingSubtotal = PackAndMoveRate

        let movingDiscount = Math.round(offer * movingSubtotal / 100)

        let movingGST = Math.round(5 * (movingSubtotal - movingDiscount) / 100)

        let noLiftCharge
        if (liftAvailable === '0') noLiftCharge = Math.round(floor * (5 * (movingSubtotal - movingDiscount + movingGST) / 100))
        else noLiftCharge = 0

        let movingTotal = movingSubtotal - movingDiscount + movingGST + noLiftCharge

        doc = new jsPDF();
        doc.setFontSize(22)
        doc.text("ESTIMATION", 85, 20)
        doc.text("ESTIMATION", 85, 20)
        doc.addImage(logo, 'PNG', 20, 40, 30, 20)
        doc.setFontSize(12)
        doc.text("Avati Safe Storage\n#429/5, M.R.B. Farms,\nNakasha Road, N.R.I. layout, \nOpp. HDFC Bank, Kalkere, Horamavu Post,\nBangalore - 560043\nBengaluru, State of Karnataka 560043", 110, 40)
        doc.text("Phone: +91 80955 89888\nEmail: avatisafestorage07@gmail.com", 110, 73)
        var currentDate = new Date()

        var date = currentDate.getDate() + '-' + (currentDate.getMonth() + 1) + '-' + currentDate.getFullYear();
        doc.text(`date: ${date}`, 110, 86)
        doc.text(props.Name, 20, 73)
        doc.text(props.Name, 20, 73)
        doc.text(props.Email, 20, 78)
        doc.text(props.Phone, 20, 83)
        doc.setLineWidth(1.5)
        doc.line(20, 90, 200, 90)

        doc.text('YOUR STORAGE RENTAL', 20, 100)
        doc.text('YOUR STORAGE RENTAL', 20, 100)
        doc.text(`Rs.${storingTotal} / Month`, 170, 100)
        doc.text(`Rs.${storingTotal} / Month`, 170, 100)

        doc.setLineWidth(0.5)
        doc.line(20, 103, 200, 103)
        doc.text(25, 110, 'Item')
        doc.text(25, 110, 'Item')
        // doc.text(125, 110, 'Unit Cost')
        doc.text(155, 110, 'Qty')
        doc.text(175, 110, 'Total')
        doc.line(20, 115, 200, 115)

        doc.text(25, 123, 'Large furniture')
        doc.text(25, 129, 'Medium furniture')
        doc.text(25, 135, 'Small furniture')
        doc.text(25, 141, 'Large appliance')
        doc.text(25, 147, 'Medium appliance')
        doc.text(25, 153, 'Small appliance')
        doc.text(25, 159, 'Large box')
        doc.text(25, 165, 'Medium box')
        doc.text(25, 171, 'Small box')

        // doc.text(125, 123, LfurnitureRate)
        // doc.text(125, 129, MfurnitureRate)
        // doc.text(125, 135, SfurnitureRate)
        // doc.text(125, 141, LapplianceRate)
        // doc.text(125, 147, MapplianceRate)
        // doc.text(125, 153, SapplianceRate)
        // doc.text(125, 159, LboxRate)
        // doc.text(125, 165, MboxRate)
        // doc.text(125, 171, SboxRate)

        doc.text(160, 123, `${Number(Largefurniture)}`)
        doc.text(160, 129, `${Number(Mediumfurniture)}`)
        doc.text(160, 135, `${Number(Smallfurniture)}`)
        doc.text(160, 141, `${Number(LargeAppliance)}`)
        doc.text(160, 147, `${Number(MediumAppliance)}`)
        doc.text(160, 153, `${Number(SmallAppliance)}`)
        doc.text(160, 159, `${Number(LargeBox)}`)
        doc.text(160, 165, `${Number(MediumBox)}`)
        doc.text(160, 171, `${Number(SmallBox)}`)

        doc.text(176, 123, `${LfurnitureRate * Largefurniture}`)
        doc.text(176, 129, `${MfurnitureRate * Mediumfurniture}`)
        doc.text(176, 135, `${SfurnitureRate * Smallfurniture}`)
        doc.text(176, 141, `${LapplianceRate * LargeAppliance}`)
        doc.text(176, 147, `${MapplianceRate * MediumAppliance}`)
        doc.text(176, 153, `${SapplianceRate * SmallAppliance}`)
        doc.text(176, 159, `${LboxRate * LargeBox}`)
        doc.text(176, 165, `${MboxRate * MediumBox}`)
        doc.text(176, 171, `${SboxRate * SmallBox}`)

        doc.line(20, 180, 200, 180)
        doc.text(156, 190, `Sub total: ${storingSubtotal}`)
        doc.text(156, 197, `GST(${GST}%): ${storingGST}`)
        doc.text(156, 204, `Total: Rs.${storingTotal}`)
        doc.setFontSize(10)
        doc.text(20, 250, 'You find moving/handling estimation on the second page.')

        doc.text(20, 262, 'Notes: Quotation is based on items mentioned. Price might change based on actual number and \nvolume of items received.')
        doc.text(20, 270, 'Boxes for packing is returnable boxes')
        doc.text(20, 275, 'Retrievel of storage items has to be initiated 5 days prior')
        doc.text(20, 280, 'Insurance covers upto 2.5 lakhs (coverage depends upon list of items and actual price of items)')

        doc.addPage()
        doc.setFontSize(22)
        doc.text("ESTIMATION", 85, 20)
        doc.addImage(logo, 'PNG', 20, 40, 30, 20)
        doc.setFontSize(12)
        doc.text("Avati Safe Storage\n#05, Mallige Rd,\nKashivishwanatha Layout,\nKrishnarajapura, Bengaluru,\nKarnataka 560036", 110, 40)
        doc.text("Phone: +91 80955 89888\nEmail: avatisafestorage07@gmail.com", 110, 70)
        doc.text(`date: ${date}`, 110, 83)
        doc.text(props.Name, 20, 70)
        doc.text(props.Email, 20, 75)
        doc.text(props.Phone, 20, 83)
        doc.setLineWidth(1.5)
        doc.line(20, 90, 200, 90)

        doc.text('YOUR MOVING AND HANDLING ESTIMATION', 20, 100)

        doc.setLineWidth(0.5)
        doc.line(20, 103, 200, 103)
        if (NeedHelp === '1') {
            doc.text(25, 110, 'Item')
            doc.text(175, 110, 'Total')
            doc.line(20, 115, 200, 115)
            doc.text(25, 125, 'Professional Packing and Moving')
            doc.text(175, 125, `${PackAndMoveRate}`)
        }
        else {
            doc.text(25, 115, `There is no moving/handling charges as you don't need help in moving/handling`)
        }

        doc.line(20, 130, 200, 130)
        doc.text(156, 140, `Sub total: ${movingSubtotal} `)
        if (movingDiscount !== 0) {
            doc.text(156, 147, `Discount(${offer}%): ${movingDiscount}`)
            doc.text(156, 154, `GST(${GST}%): ${movingGST}`)
            doc.text(156, 161, `No Lift Service: ${noLiftCharge}`)
            doc.text(156, 168, `Total: Rs.${movingTotal}`)
            doc.text(156, 168, `Total: Rs.${movingTotal}`)
            doc.text(20, 185, 'Best regards,')
            doc.text(20, 185, 'Best regards,')
            doc.text(20, 192, 'Avati storage team')
        }
        else {
            doc.text(156, 147, `GST(${GST}%): ${movingGST}`)
            doc.text(156, 154, `No Lift Service: ${noLiftCharge}`)
            doc.text(156, 161, `Total: Rs.${movingTotal}`)
            doc.text(156, 161, `Total: Rs.${movingTotal}`)
            doc.text(20, 175, 'Best regards,')
            doc.text(20, 175, 'Best regards,')
            doc.text(20, 185, 'Avati storage team')
        }
        doc.setFontSize(10)
        doc.text(20, 207, 'Notes: Quotation is based on items mentioned. Price might change based on actual number and \nvolume of items received.')
        doc.text(20, 215, 'Boxes for packing is returnable boxes')
        doc.text(20, 220, 'Retrievel of storage items has to be initiated 5 days prior')
        doc.text(20, 225, 'Insurance covers upto 2.5 lakhs (coverage depends upon list of items and actual price of items)')

        doc.text(20, 250, 'TERMS AND CONDITIONS')
        doc.text(20, 260, '1. Storage of Food, Liquor, acid or any types of liquid (like ghee tin, oil etc) is totally prohibited. \n2. Payment in Favour of  Avati Safe Storage, Banglore. \n3. 30% payment of transportation/packing charges needs to be paid for the confirmation.\n4. Delivery of items are charged on actuals (from Avati Safe Storage warehouse to your location).\n5. Avati Safe Storage reserves the right to withdraw, amend or cancel a discount at any time.')

        var base = doc.output('datauristring');

        getQuote(base, storingTotal + movingTotal, props.Location, props.storage, props.Name, props.Email, props.Phone, Largefurniture, Mediumfurniture, Smallfurniture, LargeAppliance, MediumAppliance, SmallAppliance, LargeBox, MediumBox, SmallBox, Time, Duration, NeedHelp, liftAvailable, floor)
            .then(res => res.json()).then(res => {
                if (res.status === 'success') {
                    setSubmitting(false)
                    setcurrentItem('estimate')
                }

            })
    }
    let downloadQuotation = () => {
        const randomNumber = Math.floor(Math.random() * 10000) + 1;
        doc.save(`${randomNumber}.pdf`);
        setcurrentItem('furniture')
        setLargefurniture('0')
        setMediumfurniture('0')
        setSmallfurniture('0')
        setLargeAppliance('0')
        setMediumAppliance('0')
        setSmallAppliance('0')
        setLargeBox('0')
        setSmallBox('0')
        setMediumBox('0')
        setSubmitting(false)
        setTime(null)
        setDuration(null)
        setNeedHelp(null)
        setLiftAvailable(null)
        setFloor('0')
        navigate(-1)
    }

    return (
        <div>
            {currentItem === 'furniture' ?
                <div style={{ height: '83vh' }} className='d-lg-flex justify-content-center align-items-center'>
                    <div className='w-auto'>
                        <div className='form-query'>About how many furniture do you need to store?</div>
                        <Form>
                            <Form.Group className='mt-3'>
                                <Form.Label className='form-query-label'>Number of large furnitures</Form.Label>
                                <Form.Control type='number' min="0" value={Largefurniture} onChange={(e) => setLargefurniture(e.target.value)} />
                            </Form.Group>
                            <Form.Group className='mt-3'>
                                <Form.Label className='form-query-label'>Number of medium furnitures</Form.Label>
                                <Form.Control type='number' min="0" value={Mediumfurniture} onChange={(e) => setMediumfurniture(e.target.value)} />
                            </Form.Group>
                            <Form.Group className='mt-3'>
                                <Form.Label className='form-query-label'>Number of small furnitures</Form.Label>
                                <Form.Control type='number' min="0" value={Smallfurniture} onChange={(e) => setSmallfurniture(e.target.value)} />
                            </Form.Group>
                        </Form>
                        <div className='d-flex justify-content-end mt-5'>
                            <Button className='form-continue-button' onClick={() => setcurrentItem('appliances')}>Continue</Button>
                        </div>
                    </div>
                    <Card className='size-guide-card'>
                        <Card.Header className='d-flex justify-content-center align-items-center' style={{ background: '#E62115', borderRadius: '25px 25px 0px 0px', color: '#FFF' }}>
                            <Card.Title style={{ fontSize: '1rem', fontWeight: '900' }}>Furniture guide</Card.Title>
                        </Card.Header>
                        <Card.Body className='px-5'>
                            <div className='fw-bold'>Large Furnitures</div>
                            {Loading ?
                                <div className="spinner-border mx-2" style={{ color: '#E62115', height: '1rem', width: '1rem' }} role="status">
                                    <span className="sr-only"></span>
                                </div>
                                : largeFurnitureItems ?
                                    <OverlayTrigger overlay={
                                        <Tooltip id={`large-furniture`}>
                                            {largeFurnitureItems?.sub_items?.map(item => `${item?.item_name}, `)}
                                        </Tooltip>}  >
                                        <div className='line-clamp'>
                                            {largeFurnitureItems?.sub_items?.map(item => `${item?.item_name}, `)}
                                        </div>
                                    </OverlayTrigger>
                                    : ''
                            }
                            <div className='fw-bold mt-4'>Medium Furnitures</div>
                            {Loading ?
                                <div className="spinner-border mx-2" style={{ color: '#E62115', height: '1rem', width: '1rem' }} role="status">
                                    <span className="sr-only"></span>
                                </div>
                                : mediumfurnitureItems ?
                                    <OverlayTrigger overlay={
                                        <Tooltip id={`medium-furniture`}>
                                            {mediumfurnitureItems?.sub_items?.map(item => `${item?.item_name}, `)}
                                        </Tooltip>} >
                                        <div className='line-clamp'>
                                            {mediumfurnitureItems?.sub_items?.map(item => `${item?.item_name}, `)}
                                        </div>
                                    </OverlayTrigger>
                                    : ''
                            }
                            <div className='fw-bold mt-4'>Small Furnitures</div>
                            {Loading ?
                                <div className="spinner-border mx-2" style={{ color: '#E62115', height: '1rem', width: '1rem' }} role="status">
                                    <span className="sr-only"></span>
                                </div>
                                : smallFurnitureItems ?
                                    <OverlayTrigger
                                        overlay={<Tooltip id={`small-furniture`}>
                                            {smallFurnitureItems?.sub_items?.map(item => `${item?.item_name}, `)}
                                        </Tooltip>} >
                                        <div>
                                            {smallFurnitureItems?.sub_items?.map(item => `${item?.item_name}, `)}
                                        </div>
                                    </OverlayTrigger>
                                    : ''
                            }
                        </Card.Body>
                    </Card>
                </div>
                :
                currentItem === 'appliances' ?
                    <div style={{ height: '83vh' }} className='d-lg-flex justify-content-center align-items-center'>
                        <div className='w-auto'>
                            <div className='form-query'>About how many appliances do you need to store?</div>
                            <Form>
                                <Form.Group className='mt-3'>
                                    <Form.Label className='form-query-label'>Number of large appliances</Form.Label>
                                    <Form.Control type='number' min="0" value={LargeAppliance} onChange={(e) => setLargeAppliance(e.target.value)} />
                                </Form.Group>
                                <Form.Group className='mt-3'>
                                    <Form.Label className='form-query-label'>Number of medium appliances</Form.Label>
                                    <Form.Control type='number' min="0" value={MediumAppliance} onChange={(e) => setMediumAppliance(e.target.value)} />
                                </Form.Group>
                                <Form.Group className='mt-3'>
                                    <Form.Label className='form-query-label'>Number of small appliances</Form.Label>
                                    <Form.Control type='number' min="0" value={SmallAppliance} onChange={(e) => setSmallAppliance(e.target.value)} />
                                </Form.Group>
                            </Form>
                            <div className='d-flex justify-content-end mt-5'>
                                <Button className='form-continue-button' onClick={() => setcurrentItem('boxes')}>Continue</Button>
                            </div>
                        </div>
                        <Card className='size-guide-card'>
                            <Card.Header className='d-flex justify-content-center align-items-center' style={{ background: '#E62115', borderRadius: '25px 25px 0px 0px', color: '#FFF' }}>
                                <Card.Title style={{ fontSize: '1rem', fontWeight: '900' }}>Appliance guide</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <div className='fw-bold'>Large Appliances</div>
                                {Loading ?
                                    <div className="spinner-border mx-2" style={{ color: '#E62115', height: '1rem', width: '1rem' }} role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                    : largeApplianceItems ?
                                        <OverlayTrigger overlay={
                                            <Tooltip id={`large-appliances`}>
                                                {largeApplianceItems?.sub_items?.map(item => `${item?.item_name}, `)}
                                            </Tooltip>}  >
                                            <div className='line-clamp'>
                                                {largeApplianceItems?.sub_items?.map(item => `${item?.item_name}, `)}
                                            </div>
                                        </OverlayTrigger>
                                        : ''
                                }

                                <div className='fw-bold mt-4'>Medium Appliances</div>
                                {Loading ?
                                    <div className="spinner-border mx-2" style={{ color: '#E62115', height: '1rem', width: '1rem' }} role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                    : mediumApplianceItems ?
                                        <OverlayTrigger overlay={
                                            <Tooltip id={`large-appliances`}>
                                                {mediumApplianceItems?.sub_items?.map(item => `${item?.item_name}, `)}
                                            </Tooltip>}  >
                                            <div className='line-clamp'>
                                                {mediumApplianceItems?.sub_items?.map(item => `${item?.item_name}, `)}
                                            </div>
                                        </OverlayTrigger>
                                        : ''
                                }
                                <div className='fw-bold mt-4'>Small Appliances</div>
                                {Loading ?
                                    <div className="spinner-border mx-2" style={{ color: '#E62115', height: '1rem', width: '1rem' }} role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                    : smallApplianceItems ?
                                        <OverlayTrigger overlay={
                                            <Tooltip id={`large-appliances`}>
                                                {smallApplianceItems?.sub_items?.map(item => `${item?.item_name}, `)}
                                            </Tooltip>}  >
                                            <div className='line-clamp'>
                                                {smallApplianceItems?.sub_items?.map(item => `${item?.item_name}, `)}
                                            </div>
                                        </OverlayTrigger>
                                        : ''
                                }
                            </Card.Body>
                        </Card>
                    </div>
                    :
                    currentItem === 'boxes' ?
                        <div style={{ height: '83vh' }} className='d-lg-flex justify-content-center align-items-center'>
                            <div className='w-auto'>
                                <div className='form-query'>About how many boxes do you need to store?</div>
                                <Form>
                                    <Form.Group className='mt-3'>
                                        <Form.Label className='form-query-label'>Number of large boxes</Form.Label>
                                        <Form.Control type='number' min="0" value={LargeBox} onChange={(e) => setLargeBox(e.target.value)} />
                                    </Form.Group>
                                    <Form.Group className='mt-3'>
                                        <Form.Label className='form-query-label'>Number of medium boxes</Form.Label>
                                        <Form.Control type='number' min="0" value={MediumBox} onChange={(e) => setMediumBox(e.target.value)} />
                                    </Form.Group>
                                    <Form.Group className='mt-3'>
                                        <Form.Label className='form-query-label'>Number of small boxes</Form.Label>
                                        <Form.Control type='number' min="0" value={SmallBox} onChange={(e) => setSmallBox(e.target.value)} />
                                    </Form.Group>
                                </Form>
                                <div className='d-flex justify-content-end mt-5'>
                                    <Button className='form-continue-button' onClick={() => setcurrentItem('time')}>Continue</Button>
                                </div>
                            </div>
                            <Card className='size-guide-card'>
                                <Card.Header className='d-flex justify-content-center align-items-center' style={{ background: '#E62115', borderRadius: '25px 25px 0px 0px', color: '#FFF' }}>
                                    <Card.Title style={{ fontSize: '1rem', fontWeight: '900' }}>Box storage guide</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <div>Boxes(2ft x 1.5ft x 1.5ft) are used to store kitchen<br />
                                        utensils, clothes, bedsheets, pillows, Shoes etc .,</div>
                                    <div>
                                        <span className='fw-bold mt-4'>1BHK</span> requires about <span className='fw-bold'>15 boxes</span>
                                    </div>
                                    <div>
                                        <span className='fw-bold'>2BHK</span> requires about <span className='fw-bold'>25 boxes</span>
                                    </div>
                                    <div>
                                        <span className='fw-bold'>3BHK</span> requires about <span className='fw-bold'>35 boxes</span>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        :
                        currentItem === 'time' ?
                            <div style={{ height: '83vh' }} className='d-flex flex-column justify-content-center align-items-center'>
                                <div className='form-query'>When you like to move into storage?</div>
                                <div className='mt-3'>
                                    <Form.Check
                                        className='form-query-option p-2 ps-5'
                                        type='radio'
                                        name='time'
                                        id={1}
                                        value='0'
                                        checked={Time === '0'}
                                        label='Next week' onChange={(e) => setTime(e.target.value)} />
                                    <Form.Check
                                        className='form-query-option p-2 ps-5'
                                        type='radio'
                                        name='time'
                                        id={2}
                                        value='1'
                                        checked={Time === '1'}
                                        label='Within a month' onChange={(e) => setTime(e.target.value)} />
                                    <Form.Check
                                        className='form-query-option p-2 ps-5'
                                        type='radio'
                                        name='time'
                                        id={3}
                                        value='2'
                                        checked={Time === '2'}
                                        label='I am flexible' onChange={(e) => setTime(e.target.value)} />
                                </div>
                                <div className='d-flex justify-content-end mt-5 w-25'>
                                    <Button className='form-continue-button' onClick={() => setcurrentItem('duration')}>Continue</Button>
                                </div>
                            </div>
                            :
                            currentItem === 'duration' ?
                                <div style={{ height: '83vh' }} className='d-flex flex-column justify-content-center align-items-center'>
                                    <div className='form-query'>How long are you planning to store</div>
                                    <div className='mt-3'>
                                        <Form.Check
                                            className='form-query-option p-2 ps-5'
                                            type='radio'
                                            id={1}
                                            name='duration'
                                            value='0'
                                            checked={Duration === '0'}
                                            label='Less than 1 month' onChange={(e) => setDuration(e.target.value)} />
                                        <Form.Check
                                            className='form-query-option p-2 ps-5'
                                            type='radio'
                                            id={2}
                                            name='duration'
                                            value='1'
                                            checked={Duration === '1'}
                                            label='1 to 3 months' onChange={(e) => setDuration(e.target.value)} />
                                        <Form.Check
                                            id={3}
                                            className='form-query-option p-2 ps-5'
                                            type='radio'
                                            name='duration'
                                            value='2'
                                            checked={Duration === '2'}
                                            label='3 to 12 months' onChange={(e) => setDuration(e.target.value)} />
                                        <Form.Check
                                            id={4}
                                            className='form-query-option p-2 ps-5'
                                            type='radio'
                                            name='duration'
                                            value='3'
                                            checked={Duration === '3'}
                                            label='More than 12 months' onChange={(e) => setDuration(e.target.value)} />
                                    </div>
                                    <div className='d-flex justify-content-end mt-5 w-25'>
                                        <Button className='form-continue-button' onClick={() => setcurrentItem('help')}>Continue</Button>
                                    </div>
                                </div>
                                :
                                currentItem === 'help' ?
                                    <div style={{ height: '83vh' }} className='d-flex flex-column justify-content-center align-items-center'>
                                        <div className='form-query'>Do you need packing and moving help?</div>
                                        <div className='mt-3'>
                                            <Form.Check
                                                id={1}
                                                className='form-query-option p-2 ps-5'
                                                type='radio'
                                                name='help'
                                                value='1'
                                                checked={NeedHelp === '1'}
                                                label='Yes, I need help' onChange={(e) => setNeedHelp(e.target.value)} />
                                            <Form.Check
                                                id={2}
                                                className='form-query-option p-2 ps-5'
                                                type='radio'
                                                name='help'
                                                value='0'
                                                checked={NeedHelp === '0'}
                                                label='No, I will do it myself' onChange={(e) => setNeedHelp(e.target.value)} />
                                        </div>
                                        <div className='d-flex justify-content-end w-25 mt-5'>
                                            <Button className='form-continue-button' onClick={() => NeedHelp === '0' ? getEstimatedCharge() : setcurrentItem('liftAvailable')} disabled={submitting}>
                                                Continue
                                                {submitting ?
                                                    <div className="spinner-border mx-2" style={{ color: '#E62115', height: '1rem', width: '1rem' }} role="status">
                                                        <span className="sr-only"></span>
                                                    </div> : ''}
                                            </Button>
                                        </div>
                                    </div>
                                    : currentItem === 'liftAvailable' ?
                                        <div style={{ height: '83vh' }} className='d-flex flex-column justify-content-center align-items-center'>
                                            <div className='form-query'>Do you have a lift service in your apartment?</div>
                                            <div className='mt-3'>
                                                <Form.Check
                                                    id={1}
                                                    className='form-query-option p-2 ps-5'
                                                    type='radio'
                                                    name='lift'
                                                    value='1'
                                                    checked={liftAvailable === '1'}
                                                    label='Yes, lift available' onChange={(e) => setLiftAvailable(e.target.value)} />
                                                <Form.Check
                                                    id={2}
                                                    className='form-query-option p-2 ps-5'
                                                    type='radio'
                                                    name='help'
                                                    value='0'
                                                    checked={liftAvailable === '0'}
                                                    label='No, there is no lift' onChange={(e) => setLiftAvailable(e.target.value)} />
                                            </div>
                                            <div className='d-flex justify-content-end w-25 mt-5'>
                                                <Button className='form-continue-button' onClick={() => liftAvailable === '1' ? getEstimatedCharge() : setcurrentItem('floor')} disabled={submitting}>
                                                    Continue
                                                    {submitting ?
                                                        <div className="spinner-border mx-2" style={{ color: '#E62115', height: '1rem', width: '1rem' }} role="status">
                                                            <span className="sr-only"></span>
                                                        </div> : ''}
                                                </Button>
                                            </div>
                                        </div>
                                        :
                                        currentItem === 'floor' ?
                                            <div style={{ height: '83vh' }} className=' d-flex flex-column justify-content-center align-items-center'>
                                                <div className='w-auto'>
                                                    <div className='form-query-label mb-3'>Which floor do you live on?</div>
                                                    <Form.Control type='number' min="0" value={floor} onChange={(e) => setFloor(e.target.value)} />
                                                    <div className='d-flex justify-content-end w-100 mt-5'>
                                                        <Button className='form-continue-button' onClick={getEstimatedCharge} disabled={submitting}>Continue
                                                            {submitting ?
                                                                <div className="spinner-border mx-2" style={{ color: '#E62115', height: '1rem', width: '1rem' }} role="status">
                                                                    <span className="sr-only"></span>
                                                                </div> : ''}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div style={{ height: '83vh' }} className='d-flex align-items-center justify-content-center'>
                                                <Card className='w-lg-50 border-0' style={{ backgroundColor: '#FFF', borderRadius: '25px' }}>
                                                    <Card.Body className='d-flex flex-column align-items-center'>
                                                        <Image src={thankyouIcon} className='mb-3' />
                                                        <h2 className='fw-bold' style={{ color: '#3F3D56' }}>Thank you for getting in touch!</h2>
                                                        <p style={{ color: '#3F3D56' }}>Our experts will reach you out soon.</p>
                                                        <p align='center' className='fw-bold'>We have assigned dedicated relationship manager<br />
                                                            to assist you with storage request.<br />
                                                        </p>
                                                        <p className='fw-bold'>Looking forward to serving you.</p>
                                                        <p align='center' className='fw-bold'>Best Regards,<br />
                                                            <span style={{ color: '#E62115' }}> Avati Safe Storage Team</span></p>
                                                        <Button className='form-continue-button' onClick={downloadQuotation}>
                                                            Download Your Quotation
                                                        </Button>
                                                    </Card.Body>
                                                </Card>
                                            </div>
            }</div >
    )
}

import React, { useEffect, useState } from 'react'
import { Button, Form, Image } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { Formik } from 'formik'
import ConfirmCloseModalComponent from '../ConfirmCloseModalComponent/ConfirmCloseModalComponent'
import AutomobileQueries from './AutomobileQueries'
import HouseholdQueries from './HouseholdQueries'
import BusinessQueries from './BusinessQueries'
import LuggageQueries from './LuggageQueries'
import OfficeStorage from './OfficeStorage'
import { closeButton } from '../../assets'
import { getAllCategories, getRate } from '../../api'
import './FormModalComponent.css'
import ReactGA from 'react-ga';

let rates = [], Name, Email, Phone, categories = [];
export default function FormModalComponent() {
    const navigate = useNavigate()
    const storages = ['Household Storage', 'Business Storage', 'Office Storage', 'Luggage Storage', 'Automobile Storage']
    const [showConfirmClose, setShowConfirmClose] = useState(false)
    const [storage, setStorage] = useState('Household Storage')
    const [currentItem, setcurrentItem] = useState('furniture')
    const [currentTab, setcurrentTab] = useState('location')
    const [Location, setLocation] = useState('banglore')
    const [submitting, setSubmitting] = useState(false)
    const [Loading, setLoading] = useState(true)


    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        Promise.all([getAllCategories(), getRate()])
            .then((values) => Promise.all(values.map((value) => value.json())))
            .then(res => {
                categories = res[0]
                rates = res[1]
                setLoading(false)
            }).catch(err => console.log(err))

    }, [Loading])


    let closeModal = () => {
        setSubmitting(false)
        setcurrentTab('location')
        setStorage('Household Storage')
        Name = null
        Phone = null
        Email = null
        setcurrentItem('furniture')
        setShowConfirmClose(false)
        navigate(-1)
        // props.close()
    }

    let openConfirmModal = () => {
        if (currentTab === 'submit' && storage !== 'Household Storage')
            closeModal()
        else if (currentItem === 'estimate')
            closeModal()
        else
            setShowConfirmClose(true)
    }

    let storeDetail = (values) => {
        Name = values.Name
        Phone = values.Phone
        Email = values.Email
        setcurrentTab('submit')
    }

    return (
        <div className='form-page'>
            <ConfirmCloseModalComponent openModal={showConfirmClose} closeModal={() => setShowConfirmClose(false)} cancelProject={closeModal} />
            <div className='w-100 d-flex justify-content-end border-0'>
                <Image src={closeButton} width='30px' style={{ cursor: 'pointer' }} onClick={openConfirmModal} />
            </div>
            {!Loading ?
                currentTab === 'location' ?
                    <div className='h-100 d-flex flex-column justify-content-center align-items-center'>
                        <div className='d-flex flex-column align-items-center justify-conent center'>
                            <div className='form-query'>Choose the location for storage</div>
                            <Form.Check className='ms-5 mt-3 fw-bold form-query-option p-2 ps-5 w-auto'
                                style={{ maxWidth: '200px' }}
                                type='radio'
                                label='Banglore'
                                value='banglore'
                                checked={Location === 'banglore'}
                                onChange={(e) => setLocation(e.target.value)} />
                        </div>
                        <div className='d-flex justify-content-end w-25 mt-5'>
                            <Button className='form-continue-button' onClick={() => setcurrentTab('storage')}>Continue</Button>
                        </div>
                    </div>
                    :
                    currentTab === 'storage' ?
                        <div className='h-100 d-flex flex-column justify-content-center align-items-center'>
                            <div>
                                <div className='form-query'>Choose the type of storage</div>
                                {storages.map((type, i) => (
                                    <Form.Check className='ms-5 mt-3 me-5 fw-bold form-query-option p-2 ps-5'
                                        key={i}
                                        type='radio'
                                        name='type'
                                        id={i + 1}
                                        value={type}
                                        label={type}
                                        checked={storage === type}
                                        onChange={(e) => setStorage(e.target.value)}
                                    />

                                ))}
                            </div>
                            <div className='d-flex justify-content-between w-25 mt-5'>
                                <Button className='form-previuos-button fw-bold me-3' onClick={() => setcurrentTab('location')}>Previous</Button>
                                <Button className='form-continue-button fw-bold' onClick={() => setcurrentTab('details')}>Continue</Button>
                            </div>
                        </div>
                        :
                        currentTab === 'details' ?
                            <div className='h-100 d-flex flex-column justify-content-center align-items-center'>
                                <Formik enableReinitialize={true}
                                    initialValues={{ Name: '', Phone: '', Email: '' }}
                                    onSubmit={(values) => storeDetail(values)}
                                    validate={(values) => {
                                        const errors = {};
                                        if (!values?.Name) {
                                            errors.Name = 'Enter your name'
                                            return errors;
                                        }
                                        if (!values.Phone) {
                                            errors.Phone = 'Enter your phone number'
                                            return errors
                                        } else if (values.Phone.toString().length !== 10 ||
                                            (!/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(values.Phone))) {
                                            errors.Phone = 'Enter a valid phone number'
                                            return errors
                                        }
                                        if (!values.Email) {
                                            errors.Email = 'Enter your email'
                                            return errors
                                        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Email)) {
                                            errors.Email = 'Enter a valid email address'
                                            return errors
                                        }
                                        return () => errors;
                                    }}
                                >
                                    {({ isSubmitting, dirty, handleBlur, handleChange, handleSubmit, errors, touched, values }) => (
                                        <Form onSubmit={handleSubmit} className='form-details'>
                                            <Form.Group className='mt-3 d-flex flex-column align-items-baseline'>
                                                <Form.Label className='form-query-label'>Name</Form.Label>
                                                <Form.Control name='Name' onChange={handleChange} onBlur={handleBlur} />
                                                <div style={{ color: 'red', fontSize: '12px' }}>
                                                    {errors.Name && touched.Name ? errors.Name : ''}
                                                </div>
                                            </Form.Group>
                                            <Form.Group className='mt-3 d-flex flex-column align-items-baseline'>
                                                <Form.Label className='form-query-label'>Mobile number</Form.Label>
                                                <Form.Control name='Phone' onChange={handleChange} onBlur={handleBlur} />
                                                <div style={{ color: 'red', fontSize: '12px' }}>
                                                    {errors.Phone && touched.Phone ? errors.Phone : ''}
                                                </div>
                                            </Form.Group>
                                            <Form.Group className='mt-3 d-flex flex-column align-items-baseline'>
                                                <Form.Label className=' form-query-label'>Email</Form.Label>
                                                <Form.Control name='Email' onChange={handleChange} onBlur={handleBlur} />
                                                <div style={{ color: 'red', fontSize: '12px' }}>
                                                    {errors.Email && touched.Email ? errors.Email : ''}
                                                </div>
                                            </Form.Group>
                                            <div className='d-flex justify-content-between mt-5'>
                                                <Button className='form-previuos-button fw-bold me-3' onClick={() => setcurrentTab('storage')}>Previous</Button>
                                                <Button className='form-continue-button' type='submit' disabled={submitting}>Continue</Button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                            :
                            currentTab === 'submit' ?
                                storage === 'Household Storage' ?
                                    <HouseholdQueries Name={Name} Email={Email} Phone={Phone} storage={storage} Location={Location} rates={rates} close={() => closeModal()}
                                        category={categories.filter(category => category.name === 'Household Storage')[0]} />
                                    :
                                    storage === 'Automobile Storage' ?
                                        <AutomobileQueries Name={Name} Email={Email} Phone={Phone} storage={storage} Location={Location} rates={rates} close={() => closeModal()} />
                                        :
                                        storage === 'Luggage Storage' ?
                                            <LuggageQueries Name={Name} Email={Email} Phone={Phone} storage={storage} Location={Location} rates={rates} close={() => closeModal()} />
                                            :
                                            storage === 'Business Storage' ?
                                                <BusinessQueries Name={Name} Email={Email} Phone={Phone} storage={storage} Location={Location} rates={rates} close={() => closeModal()} />
                                                :
                                                storage === 'Office Storage' ?
                                                    <OfficeStorage Name={Name} Email={Email} Phone={Phone} storage={storage} Location={Location} rates={rates} close={() => closeModal()} />
                                                    : ''
                                :
                                ''

                :
                <div className='d-flex justify-content-center align-items-center h-100'>
                    <div className="spinner-border mx-2" style={{ color: '#E62115', height: '2rem', width: '2rem' }} role="status">
                        <span className="sr-only"></span>
                    </div>
                </div>
            }
        </div>

    )
}

import React from 'react'
import StorageServicesSection from './StorageServicesSection'
import { FooterComponent } from '../../SharedComponents'
import TestimonialSection from './TestimonialSection'
import HowItWorkSection from './HowItWorkSection'
import AboutUsSection from './AboutUsSection'
import FeatureSection from './FeatureSection'
import HeroSection from './HeroSection'
import './LandingPageView.css'
import BlogsSection from './BlogsSection'

export default function LandingPageView() {
    return (
        <div>
            {/* <NavbarComponent /> */}
            <HeroSection />
            <StorageServicesSection />
            <HowItWorkSection />
            <AboutUsSection />
            <FeatureSection />
            {/* <ProcessSection /> */}
            {/* <CallUsSection /> */}
            <BlogsSection />
            <TestimonialSection />
            {/* <FreeQuoteSection /> */}
            <FooterComponent />
        </div>
    )
}

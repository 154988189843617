import { Field, Formik } from "formik";
import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

export default function GetAfreeQuoteComponent({ getFreeQute }) {
  return (
    <div className="free-quote-form position-absolute">
      <Formik
        enableReinitialize={true}
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          message: "",
        }}
        onSubmit={getFreeQute}
        validate={(values) => {
          const errors = {};
          if (!values?.firstName) errors.firstName = "Enter your first name";
          if (!values?.lastName) errors.lastName = "Enter your last name";
          if (!values.phone) errors.phone = "Enter your phone number";
          if (!values.email) errors.email = "Enter your email";
          if (!values?.message) errors.message = "Enter your message";
          return errors;
        }}
      >
        {({
          isSubmitting,
          dirty,
          handleBlur,
          handleChange,
          handleSubmit,
          errors,
          touched,
          values,
          resetForm,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Form.Group className="d-flex flex-column">
                  <Form.Label className="text-start">First Name</Form.Label>
                  <Field
                    className="form-control"
                    name="firstName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {errors.firstName && touched.firstName
                      ? errors.firstName
                      : ""}
                  </div>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="d-flex flex-column">
                  <Form.Label className="text-start">Last Name</Form.Label>
                  <Field
                    className="form-control"
                    name="lastName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {errors.lastName && touched.lastName ? errors.lastName : ""}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Form.Group className="d-flex flex-column">
                  <Form.Label className="text-start">Email</Form.Label>
                  <Field
                    className="form-control"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {errors.email && touched.email ? errors.email : ""}
                  </div>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="d-flex flex-column">
                  <Form.Label className="text-start">Phone Number</Form.Label>
                  <Field
                    className="form-control"
                    name="phone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {errors.phone && touched.phone ? errors.phone : ""}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="d-flex flex-column mt-3">
              <Form.Label className="text-start">Message</Form.Label>
              <Field
                className="form-control"
                as="textarea"
                rows={4}
                name="message"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <div style={{ color: "red", fontSize: "12px" }}>
                {errors.message && touched.message ? errors.message : ""}
              </div>
            </Form.Group>
            <Button
              type="submit"
              className="landing-page-button mt-4"
              style={{ fontSize: "0.9em" }}
            >
              Get a Free Quote
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

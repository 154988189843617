import React from 'react'
import { Image } from 'react-bootstrap'
import { whatsappIcon } from '../../assets';
import './WhatsappFloatingComponent.css'

export default function WhatsappFloatingComponent() {

    let scrollToTop = () => {
        window.open(`https://wa.me/+918095589888`);
    }

    return (
        <div>
            <div className='whatsapp-chat-component d-flex justify-content-center align-items-center' onClick={scrollToTop}>
                <Image src={whatsappIcon} className='whatsapp-icon' />
                {/* <RiWhatsappLine size="30px" style={{ color: '#fff' }} /> */}
            </div>

        </div>
    )

}
import React from 'react'
import { Button, Image, Row } from 'react-bootstrap'
import { aboutusImage } from '../../assets'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useNavigate } from 'react-router-dom';


export default function AboutUsSection() {
    const navigate =useNavigate()
    return (
        <Row className='about-us-section mx-0' id='about-us'>
            <AnimationOnScroll duration={2} animateIn="animate__slideInLeft" className='col-lg-auto col-sm-12'>
                <h4 className='text-start'>WELCOME TO THE TEST</h4>
                <h2 className='text-start' style={{ color: 'var(--primary-color)', fontWeight: '800', fontSize: '2.5em' }}>BEST MOVERS</h2>
                <h6 className='text-start fw-bold'>You may see many moving companies, so why choose us?<br />
                    Let us tell you our special features..</h6>
                <p align='left' className='fst-italic mt-4'> Mover located in bangalore; one of the major cities of India. We <br />
                    are a one-stop solution to all your moving problems. From<br />
                    packing to relocating, we offer all that you have in mind
                    <br /> when you think of moving.</p>
                <ul>
                    <li className='text-start'>Your requirement is our wish</li>
                    <li className='text-start'>Moving is quick and easy</li>
                    <li className='text-start'> We are affordable</li>
                </ul>
                <div className='d-flex mt-4'>
                    <Button className='px-5 landing-page-button' onClick={()=>navigate('about-us')}>About Us</Button>
                </div>
            </AnimationOnScroll>
            <AnimationOnScroll duration={2} animateIn="animate__slideInRight" className='col-lg-auto col-sm-12 col mt-4 mt-lg-0'>
                <Image src={aboutusImage} className='about-us-image' />
            </AnimationOnScroll>
        </Row >
    )
}

import React, { Component } from "react";
import { Card, Container, Row, Col, Button } from "react-bootstrap";
import { FooterComponent, NavbarComponent } from "../../SharedComponents";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import "./BlogSectionView.css"; // Import your custom CSS styles
import { getBlogs } from "../../api"; // Updated import
//import BlogDetailModal from "./BlogDetailModal"; // Correct import


function sanitizeTitleForURL(title) {
  return title
    .toLowerCase()
    .replace(/[^a-zA-Z0-9\s]/g, '') // Remove special characters
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .trim(); // Remove leading/trailing hyphens
}

export default class BlogSectionView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blogs: [],
      selectedBlog: null,
      showModal: false, // Track whether the modal is displayed
    };
  }

  async componentDidMount() {
    try {
      const response = await getBlogs(); // Updated function name
      if (response.ok) {
        const data = await response.json();
        this.setState({ blogs: data });
      } else {
        console.error("Failed to fetch blogs");
      }
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }

    ReactGA.pageview(window.location.pathname + window.location.search);
    window.scrollTo(500, 0);
  }

  // Function to show the modal with the selected blog
  showBlogDetail = (blog) => {
    // Instead of displaying the blog post in the modal, navigate to a new page/section
    this.setState({ selectedBlog: blog });
  };
  // Function to hide the modal
  hideBlogDetail = () => {
    this.setState({ showModal: false, selectedBlog: null });
  };

  render() {
    return (
      <div>
        <Helmet>
          <title>
            Blog Section - Avati Safe Storage - Storage Places in Bangalore
          </title>
          <meta
            name="description"
            content="Avatisafestorage.com takes great pride in our services. Here we mentioned all payment details with upi id, now you can pay easily through upi id also."
          />
        </Helmet>
        <NavbarComponent />
        <Container className="blog-section-view mt-5">
          <div className="heading-container">
            <h1 style={{ fontWeight: "800", color: "var(--primary-color)" }}><br></br><br></br>
              Blog Section
            </h1>
          </div>
          <Row>
            {this.state.blogs.map((blog, index) => (
              <Col key={index} lg={4} md={6} sm={12} className="mb-4">
                <Card className="blog-card">
                  <Card.Img
                    variant="top"
                    src={`https://www.avatisafestorage.com/admin/uploads/blog/thumbnail/${blog.thumbnail}`}
                    alt={`Thumbnail for ${blog.title}`}
                    style={{ width: "400px", height: "250px" }}
                  />
                  <Card.Body>
                    <Card.Title style={{ fontWeight: "800" }}>
                      {blog.title}
                    </Card.Title>
                    <Card.Text>
                      Added Date:{" "}
                      {new Date(blog.added_date * 1000).toLocaleString()}
                    </Card.Text>
                    {/* Button to show the modal */}
                    <Link
          to={`/blog/${sanitizeTitleForURL(blog.title)}`}
          className="custom-read-more-button"
        >
          Read More
        </Link>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
        <FooterComponent />

       
      </div>
    );
  }
}

import React from 'react'
import { Card, Col, Image, Row } from 'react-bootstrap'
import { testimonialClientImage } from '../../assets'
import { AnimationOnScroll } from 'react-animation-on-scroll';


export default function TestimonialSection() {
    return (
        <div className='testimonial-section'>
            <h6 className='fw-bolder'>TESTIMONIALS</h6>
            <h1 style={{ color: 'var(--primary-color)', fontWeight: '800' }}>What Clients Say</h1>
            <p style={{ fontWeight: '500', marginBottom: '62px' }}>These are the stories of our customers who have joined us with great<br />
                pleasure when using this crazy feature.
            </p>
            <Row className='mx-0 justify-content-around'>
                {/* <Col className='col-md-4 col-12'>
                    <AnimationOnScroll duration={2} animateIn="animate__slideInUp" className='card position-relative testimonial-card'>
                        <Image className='testimonial-client-image position-absolute' src={testimonialClientImage} />
                        <Card.Body style={{ padding: '72px 23px 43px 36px' }}>
                            <Card.Text className='text-start'>I had to shift to a new home and
                                I am very happy that i chose Avati
                                Safe storage, their pro workers
                                took care of everything, like literally
                                everything. I am short of words to
                                define the support they had provided.
                                Furniture arrived on time in my new
                                house in one piece. Thanks a lot!!!
                            </Card.Text>
                            <Card.Text className='text-end'>Vinay<br />
                                KR Puram, Bangalore</Card.Text>
                        </Card.Body>
                    </AnimationOnScroll>
                </Col> */}
                <Col className='col-lg-4 col-md-6 col-12'>
                    <AnimationOnScroll animateIn="animate__slideInUp" duration={2} delay={150} className='card position-relative testimonial-card mt-5 mt-md-0'>
                        <Image className='testimonial-client-image position-absolute' src={testimonialClientImage} />
                        <Card.Body style={{ padding: '72px 23px 43px 36px' }}>
                            <Card.Text className='text-start'>I had to shift to a new home and
                                I am very happy that i chose Avati
                                Safe storage, their pro workers
                                took care of everything, like literally
                                everything. I am short of words to
                                define the support they had provided.
                                Furniture arrived on time in my new
                                house in one piece. Thanks a lot!!!
                            </Card.Text>
                            <Card.Text className='text-end'>Vinay<br />
                                KR Puram, Bangalore</Card.Text>
                        </Card.Body>
                    </AnimationOnScroll>
                </Col>
                {/* <Col className='col-md-4 col-12'>
                    <AnimationOnScroll animateIn="animate__slideInUp" duration={2} delay={250} className='card position-relative testimonial-card mt-5 mt-md-0'>
                        <Image className='testimonial-client-image position-absolute' src={testimonialClientImage} />
                        <Card.Body style={{ padding: '72px 23px 43px 36px' }}>
                            <Card.Text className='text-start'>I had to shift to a new home and
                                I am very happy that i chose Avati
                                Safe storage, their pro workers
                                took care of everything, like literally
                                everything. I am short of words to
                                define the support they had provided.
                                Furniture arrived on time in my new
                                house in one piece. Thanks a lot!!!
                            </Card.Text>
                            <Card.Text className='text-end'>Vinay<br />
                                KR Puram, Bangalore</Card.Text>
                        </Card.Body>
                    </AnimationOnScroll>
                </Col> */}
            </Row>
        </div>
    )
}

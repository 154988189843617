import React from "react";
import { MdCall } from "react-icons/md";
import "./CallFloatingComponent.css";

export default function CallFloatingComponent() {
  return (
    <div>
      <div className="call-component d-flex justify-content-center align-items-center">
        <a href="tel:+918095589888" className="call-floating-icon-wrapper">
          <MdCall color="red" className='call-floating-icon'/>
        </a>
      </div>
    </div>
  );
}

import React from "react";
import { Image, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  automobileStorageImage,
  businessStorageImage,
  callIcon,
  facebookIcon,
  householdStorageImage,
  logo,
  luggageStorageImage,
  messageIcon,
  officeStorageImage,
  packersAndMoversImage,
} from "../../assets";
import WhatsappFloatingComponent from "../WhatsappFloatingComponent/WhatsappFloatingComponent";
import "./FooterComponent.css";

export default function FooterComponent() {
  let navigate = useNavigate();
  let gotoContact = () => {
    let element = document.getElementById("contact-section");
    let aboutlink = document.getElementById("about-link");
    let servicelink = document.getElementById("service-link");
    let processlink = document.getElementById("process-link");
    aboutlink.className = "navbar-link px-4";
    servicelink.className = "navbar-link px-4";
    processlink.className = "navbar-link px-4";
    element.scrollIntoView(true);
  };
  return (
    <div className="footer-component">
      <Row className="justify-content-between">
        <div className="w-auto d-flex flex-column">
          <Image src={logo} className="logo mb-4" />
          <h5 className="text-start mt-5 mt-lg-0" style={{ fontWeight: "500" }}>
            {" "}
            Contact us
          </h5>
          <hr />
          <Row className="mb-2">
            <Image src={callIcon} style={{ height: "auto", width: "54px" }} />
            <div
              className="text-start w-auto"
              style={{
                textDecoration: "none",
                color: "#FFF",
                fontWeight: "500",
                textAlign: "start",
              }}
            >
              +91 80955 89888
            </div>
          </Row>
          <Row className="mb-4">
            <Image
              src={messageIcon}
              style={{ height: "auto", width: "54px" }}
            />
            <div
              className="text-start w-auto"
              style={{
                textDecoration: "none",
                color: "#FFF",
                fontWeight: "500",
                textAlign: "start",
              }}
            >
              contact@avatisafestorage.com
            </div>
          </Row>
          <div className="d-flex">
            {/* <Button className='me-4 fw-bold px-3' style={{ backgroundColor: 'initial', borderColor: '#C59C34' }} onClick={gotoContact}>Contact Us</Button> */}
            <a
              href="https://www.facebook.com/people/Avati-Safe-Storage/100063778741047/"
              target="_blank"
              rel="noreferrer"
            >
              <Image src={facebookIcon} />
            </a>
          </div>
        </div>
        <div className="w-auto">
          <h5 className="text-start mt-5 mt-lg-0" style={{ fontWeight: "500" }}>
            Bangalore
          </h5>
          <hr />
          <div
            style={{
              textDecoration: "none",
              color: "#FFF",
              fontWeight: "500",
              textAlign: "start",
            }}
            onClick={() =>
              navigate("/banglore/household-storage", {
                state: {
                  image: householdStorageImage,
                  title: "HOUSEHOLD STORAGE",
                },
              })
            }
          >
            House Hold Storage
          </div>
          <div
            style={{
              textDecoration: "none",
              color: "#FFF",
              fontWeight: "500",
              textAlign: "start",
            }}
            onClick={() =>
              navigate("/banglore/business-storage", {
                state: {
                  image: businessStorageImage,
                  title: "BUSINESS STORAGE",
                },
              })
            }
          >
            Business Storage
          </div>
          <div
            style={{
              textDecoration: "none",
              color: "#FFF",
              fontWeight: "500",
              textAlign: "start",
            }}
            onClick={() =>
              navigate("/banglore/office-storage", {
                state: { image: officeStorageImage, title: "OFFICE STORAGE" },
              })
            }
          >
            Office Storage
          </div>
          <div
            style={{
              textDecoration: "none",
              color: "#FFF",
              fontWeight: "500",
              textAlign: "start",
            }}
            onClick={() =>
              navigate("/banglore/luggage-storage", {
                state: { image: luggageStorageImage, title: "LUGGAGE STORAGE" },
              })
            }
          >
            Luggage Storage
          </div>
          <div
            style={{
              textDecoration: "none",
              color: "#FFF",
              fontWeight: "500",
              textAlign: "start",
            }}
            onClick={() =>
              navigate("/banglore/automobile-storage", {
                state: {
                  image: automobileStorageImage,
                  title: "AUTOMOBILE STORAGE",
                },
              })
            }
          >
            Automobile Storage
          </div>
          <div
            style={{
              textDecoration: "none",
              color: "#FFF",
              fontWeight: "500",
              textAlign: "start",
            }}
            onClick={() =>
              navigate("/banglore/packers-and-movers", {
                state: {
                  image: packersAndMoversImage,
                  title: "PACKERS AND MOVERS",
                },
              })
            }
          >
            Packers And Movers
          </div>
          <Link
            to="/payment-details"
            className="d-flex m4-3 text-decoration-none"
            style={{ fontWeight: "600", color: "#FFF" }}
          >
            Payment details
          </Link>
          <Link
            to="/privacy-policy"
            className="d-flex m4-3 mb-4 text-decoration-none"
            style={{ fontWeight: "600", color: "#FFF" }}
          >
            Privacy Policy
          </Link>
        </div>
      </Row>
      <div style={{ fontWeight: "500" }}>
        Avati Safe Storage © 2022. All Rights Reserved.
        <br />
        Developed by fynextinnovations
      </div>
      <WhatsappFloatingComponent />
    </div>
  );
}

import React from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { heroImage } from "../../assets";
import {
  CallFloatingComponent,
  EntryExitPopup,
  Navbar2Component,
  NavbarComponent,
  WhatsappFloatingComponent,
} from "../../SharedComponents";
import "./LandingPage2.css";

export default function LandingPage2() {
  const navigate = useNavigate();

  return (
    <div className="position-relative hero-section landingPage2Wrapper">
      <Navbar2Component />
      <NavbarComponent top={60} showContactUs={true}/>
      <EntryExitPopup />
      <Row className="mx-0 hero-section-details">
        <Col className="d-flex flex-column justify-content-center">
          <h1 className="hero-title text-start mt-4" style={{ zIndex: 11 }}>
            One of the best <br />
            <span style={{ color: "var(--primary-color)", fontSize: "1.15em" }}>
              Self Storage Service
            </span>
            <br />
            provider in and out of <br />
            Bangalore. <br />
          </h1>
          <div className="d-flex justify-content-start mt-2">
            <Button
              className="landing-page-button w-auto px-3"
              onClick={() => navigate("/get-quote")}
            >
              Get a Free Quote
            </Button>
          </div>
        </Col>
        <Col className="d-flex justify-content-end">
          <Image className="hero-image" src={heroImage} />
        </Col>
      </Row>
      {/* </div> */}
      <WhatsappFloatingComponent />
      <CallFloatingComponent />
    </div>
  );
}

import React, { Component } from 'react'
import { Image, Row } from 'react-bootstrap'
import { FooterComponent, NavbarComponent } from '../../SharedComponents'
import { packersAndMoversImage } from '../../assets'
import './WhyChooseUsView.css'

export default class WhyChooseUsView extends Component {
    componentDidMount() {
        window.scrollTo(500, 0)
    }
    render() {
        return (
            <div>
                <NavbarComponent />
                <div className='why-choose-us-view'>
                    <h3 style={{ color: 'var(--primary-color)', fontWeight: '700' }}>WHY CHOOSE OUR STORAGE SERVICE</h3>
                    <Row className='section-1-row mx-0'>
                        <div className='col-lg-7'>
                            <p align='left' style={{ fontWeight: '500' }}>We provide complete safe, secure solution on which you can rely upon. We take <br />
                                utmost care while packing, loading/unloading, transporting, storage and delivery.
                                <br /><br />
                                <ul>
                                    <li>Complete end to end solution</li>
                                    <li>Monthly cost at very cheap price</li>
                                    <li>Professional packaging</li>
                                    <li>Pest controlled facility</li>
                                    <li>24*7 CCTV monitoring</li>
                                </ul>
                                Easy, safe and secure payment option<br />
                                Don’t worry we have got everything covered. Our storage solution would be<br />
                                suitable for all your requirements. Our trained staff will reach your home, they will<br />
                                do the heavy lifting, pack your valuables safely and transport them to our best<br />
                                24*7 monitored facility. You can keep them here as long as you like and can easily<br />
                                retrieve your some / all of the belongings. All this at the best price!
                                <br /><br />
                                You need not break a sweat.</p>
                        </div>
                        <div className='col-lg-5 d-flex align-items-center'>
                            <Image src={packersAndMoversImage} className='why-choose-us-view-image' />
                        </div>
                    </Row>
                </div>
                <FooterComponent />
            </div>
        )
    }
}

import { environment } from "./env";

const baseURL = environment.hostname

let getQuote = async (pdf, total, location, type, name, email, phone, largeFurn, medFurn, smallFurn, largeAppl, medAppl,
    smallAppl, largeBox, medBox, smallBox, time, duration, help, liftAvailable, floor, bikes, hatchBack, sedan, suv,
    SmallBag, mediumbag, LargeTrolly, businessSpace, officeSpace) => {
    let formData = {}
    formData.location = location
    formData.type_of_storage = type
    formData.name = name
    formData.phone = phone
    formData.email = email
    formData.large_furniture = largeFurn
    formData.medium_furniture = medFurn
    formData.small_furniture = smallFurn
    formData.large_appliance = largeAppl
    formData.medium_appliance = medAppl
    formData.small_appliance = smallAppl
    formData.large_box = largeBox
    formData.medium_box = medBox
    formData.small_box = smallBox
    formData.time = time
    formData.need_help = help
    formData.duration = duration
    formData.lift_available = liftAvailable
    formData.floor_no = floor
    formData.bike = bikes
    formData.hatchback = hatchBack
    formData.sedan = sedan
    formData.suv = suv
    formData.small_bag = SmallBag
    formData.medium_bag = mediumbag
    formData.large_trolly = LargeTrolly
    formData.office_size = officeSpace
    formData.business_size = businessSpace
    formData.pdf = pdf
    formData.total = total

    return await fetch(`${baseURL}/get_quote`, {
        method: 'POST',
        body: JSON.stringify(formData)
    })
}

let contactUs = async (values) => {
    return await fetch(`${baseURL}/contact_us?first_name=${values.firstName}&last_name=${values.lastName}&email=${values.email}&phone=${values.phone}&move_from=${values.moveFrom}&move_to=${values.moveTo}&message=${values.message}`)
}

let getRate = async () => {
    return await fetch(`${baseURL}/get_itemrate`)
}
let getBlogs = async () => {
    return await fetch(`${baseURL}/get_blogs`)
}
let getAllCategories = async () => {
    return await fetch(`${baseURL}/all_categories`)
}

let getCategoryWiseItems = async (id) => {
    return await fetch(`${baseURL}/category_wise_itemlist?category_id=${id}`)
}
let getBlogDetails = async (id) => {
    const sanitizedId = id.replace(/-/g, ' ');
    //console.log("Title:", sanitizedId);
    return await fetch(`${baseURL}/blogdetail?title=${sanitizedId}`)
}

export {
    getCategoryWiseItems,
    getAllCategories,
    contactUs,
    getQuote,
    getRate,
    getBlogs,
    getBlogDetails
}
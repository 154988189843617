import React, { useEffect } from "react";
import { Image, Row } from "react-bootstrap";
import { householdStorageLongImage } from "../../assets";
import { FooterComponent, NavbarComponent } from "../../SharedComponents";
import FreeQuoteSection from "../ContactUsView/ContactUsView";
import "./StorageDetailsView.css";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";

export default function HouseholdStorageView() {
  document.querySelector("html").scrollTo(0, 0);

  const changeBackground = () => {
    if (window.scrollY >= 44) {
      document.getElementById("navbar-component").style.backgroundColor =
        "#FFF";
    } else {
      document.getElementById("navbar-component").style.backgroundColor =
        "initial";
    }
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    changeBackground();
    window.addEventListener("scroll", changeBackground);
  });
  return (
    <div>
      <Helmet>
        <title>Best household storage in Bangalore | Household Warehouse</title>
        <meta
          name="description"
          content="Looking for Household Storage Service in Bangalore? Avati Safe Storage offers a on demand household storage service. To explore our services Call Today!"
        />
      </Helmet>
      <NavbarComponent />
      <div className="section-1">
        <h1 style={{ color: "var(--primary-color)", fontWeight: "700" }}>
          Household Storage in Bangalore
        </h1>
        <Row className="section-1-row">
          <div className="col-lg-5 d-flex align-items-center mb-4 mb-lg-0">
            <Image
              src={householdStorageLongImage}
              className="household-storage-view-image"
              alt="household storage in Bangalore"
            />
          </div>
          <div className="col-lg-7 ps-4">
            <p align="left" style={{ fontWeight: "500" }}>
              Try our best on demand house hold storage service in bangalore.
              <br />
              <br />
              Are you relocating to new home? Travelling overseas for short/long
              duration? Are you travelling and need a secure place for storage
              of your belongings? Do you have additional stuff for which you are
              looking for the best and cheap storage service?
              <br /> <br />
              Sometimes you may need to shift from your current place to a newer
              place and which may require lots of travelling or packing and you
              are wasting so much of your time, you would have done some other
              important work apart from this. Hence we are here with one of our
              best on demand house hold storage service in bangalore.
              <br />
              <br />
              Whether you have small items to be stored or need to store your
              all the belongings. We undertake all kinds of requirements and we
              return them back anywhere at your choice be it in half a day or
              next day, you can instantly retrieve your belongings back.
              <br />
              <br />
              We have taken utmost care for all the customers who have trusted
              in us and we hence have successfully made them happy with our
              services.
              <br />
              <ul>
                <li>You can easily increase or decrease your storage space.</li>
                <li>Pick up and delivery of goods at any place.</li>
                <li>We also undertake Inter or Outer state transportation.</li>
                <li>Our box storage service is one of a kind.</li>
                <li>
                  Our facility is 24*7 monitored and hence your stuff is safe at
                  all times.
                </li>
                <li>We have complete pest resistant facility.</li>
              </ul>
            </p>
          </div>
        </Row>
      </div>
      <FreeQuoteSection />
      <FooterComponent />
    </div>
  );
}

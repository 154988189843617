import React from 'react'
import { Button, Card, Image } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { thankyouIcon } from '../../assets'

export default function ThankYouView() {
    let navigate= useNavigate()
    return (
        <div style={{ height: '83vh' }} className='d-flex align-items-center justify-content-center'>
            <Card className='w-lg-50 border-0' style={{ backgroundColor: '#FFF', borderRadius: '25px' }}>
                <Card.Body className='d-flex flex-column align-items-center'>
                    <Image src={thankyouIcon} className='mb-3' />
                    <h2 className='fw-bold' style={{ color: '#3F3D56' }}>Thank you for getting in touch!</h2>
                    <p style={{ color: '#3F3D56' }}>Our experts will reach you out soon.</p>
                    {/* <p align='center' className='fw-bold'>We have assigned dedicated relationship manager<br />
                        to assist you with storage request.<br />
                    </p> */}
                    <p className='fw-bold'>Looking forward to serving you.</p>
                    <p align='center' className='fw-bold'>Best Regards,<br />
                        <span style={{ color: '#E62115' }}> Avati Safe Storage Team</span></p>
                    <Button className='form-continue-button' onClick={()=>navigate('/')}>
                        Back to Home
                    </Button>
                </Card.Body>
            </Card>
        </div>
    )
}

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { contactUs } from "../../api";
import GetAfreeQuoteComponent from "../GetAfreeQuoteComponent/GetAfreeQuoteComponent";
import "./EntryExitPopup.css";

function EntryExitPopup() {
  const [showEntryPopup, setShowEntryPopup] = useState(false);
  const [showExitPopup, setShowExitPopup] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Show entry popup after 5 seconds
    const timerId = setTimeout(() => {
      setShowEntryPopup(true);
    }, 5000);

    // Set up beforeunload event listener
    // const handleBeforeUnload = (event) => {
    //   event.preventDefault();
    //   event.returnValue = "";
    //   setShowExitPopup(true);
    //   return "";
    // };

    // window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      clearTimeout(timerId);
    //   window.removeEventListener("beforeunload", handleBeforeUnload);
      // hide the exit popup when the component is unmounted
      setShowExitPopup(false);
    };
  }, []);

  const handleEntryClose = (e) => {
    e.preventDefault();
    setShowEntryPopup(false);
  };

  const handleExitClose = (e) => {
    e.preventDefault();
    setShowExitPopup(false);
  };

  let getFreeQute = (values, { resetForm }) => {
    console.log(values);
    contactUs(values)
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        if (res.status === "success") {
          resetForm({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            message: "",
          });
          navigate("/thank-you");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      {showEntryPopup && (
        <div className={`entry-popup ${showEntryPopup ? "show" : ""}`}>
          <div className="popup-content">
            <div className="d-flex w-100 justify-content-end">
              <MdClose onClick={handleEntryClose} />
            </div>

            <div className="col-md-6 entry-exit-form-wrapper position-relative">
              <GetAfreeQuoteComponent getFreeQute={getFreeQute} />
            </div>
          </div>
        </div>
      )}

      {/* Show exit popup */}
      {showExitPopup && (
        <div className={`entry-popup ${showExitPopup ? "show" : ""}`}>
          <div className="popup-content">
            <div className="d-flex w-100 justify-content-end">
              <MdClose onClick={handleExitClose} />
            </div>

            <div className="col-md-6 entry-exit-form-wrapper position-relative">
              <GetAfreeQuoteComponent getFreeQute={getFreeQute} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EntryExitPopup;

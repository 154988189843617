import React from 'react'
import { Image, Row } from 'react-bootstrap'
import { estimateIcon, flatRateFeeIcon, serviceIcon } from '../../assets'

export default function FeatureSection() {
    return (
        <Row className='feature-section justify-content-between'>
            <div  className='d-flex flex-column feature-1'>
                <Image className='ms-4' src={estimateIcon} width='60px' height='70px' />
                <h5 className='text-start mt-2' style={{ color: 'var(--primary-color)' }}>Free Estimate</h5>
                <p align='left'> Extreme attention to detail is<br /> the essence of Boo’s unique<br /> design concepts.</p>
            </div>
            <div  className='d-flex flex-column feature-2'>
                <Image className='ms-4' src={flatRateFeeIcon} width='60px' height='70px' />
                <h5 className='text-start mt-2' style={{ color: 'var(--primary-color)' }}>Flat Rate Fees</h5>
                <p align='left'> Extreme attention to detail is<br /> the essence of Boo’s unique<br /> design concepts.</p>
            </div>
            <div className='d-flex flex-column feature-3'>
                <Image className='ms-4' src={serviceIcon} width='60px' height='70px' />
                <h5 className='text-start mt-2' style={{ color: 'var(--primary-color)' }}>24/7 Service</h5>
                <p align='left'> Extreme attention to detail is<br /> the essence of Boo’s unique<br /> design concepts.</p>
            </div>
        </Row>
    )
}

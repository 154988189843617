import jsPDF from 'jspdf'
import React, { useEffect, useState } from 'react'
import { Button, Form, Card, Image } from 'react-bootstrap'
import { getQuote } from '../../api'
import { logo, thankyouIcon } from '../../assets'

let doc, GST, offer, PackAndMoveRate
export default function AutomobileQueries(props) {
    const [currentItem, setcurrentItem] = useState('quantity')
    const [submitting, setSubmitting] = useState(false)
    const [hatchBack, setHatchBack] = useState(0)
    const [Duration, setDuration] = useState(null)
    const [NeedHelp, setNeedHelp] = useState(null)
    const [Bikes, setBikes] = useState(0)
    const [sedan, setSedan] = useState(0)
    const [Time, setTime] = useState(null)
    const [suv, setSUV] = useState(0)

    useEffect(() => {
        GST = props.rates.filter(item => item.title === 'GST')[0]?.rate
        offer = props.rates[props.rates.length - 1].offer_rate
    }, [props.rates])

    let getEstimatedCharge = () => {
        setSubmitting(true)
        let storingSubtotal = Number(Bikes) * 500 + Number(suv) * 3500 + Number(sedan) * 2700 + Number(hatchBack) * 2200
        let storingGST = GST * storingSubtotal / 100

        let storingTotal = storingSubtotal + storingGST

        if (NeedHelp === '1') {
            let packAndMoveRateRatio = props.rates.filter(rate => rate.title === 'Transportation Charge Ratio')[0]?.rate?.split(':')
            PackAndMoveRate = packAndMoveRateRatio[1] * (packAndMoveRateRatio[0] * storingSubtotal)
        }
        else PackAndMoveRate = 0

        let movingSubtotal = PackAndMoveRate

        let movingDiscount = Math.round(offer * movingSubtotal / 100)

        let movingGST = Math.round(5 * (movingSubtotal - movingDiscount) / 100)

        let movingTotal = movingSubtotal - movingDiscount + movingGST

        doc = new jsPDF();
        doc.setFontSize(22)
        doc.text("ESTIMATION", 85, 20)
        doc.text("ESTIMATION", 85, 20)
        doc.addImage(logo, 'PNG', 20, 40, 30, 20)
        doc.setFontSize(12)
        doc.text("Avati Safe Storage\n#429/5, M.R.B. Farms,\nNakasha Road, N.R.I. layout, \nOpp. HDFC Bank, Kalkere, Horamavu Post,\nBangalore - 560043\nBengaluru, State of Karnataka 560043", 110, 40)
        doc.text("Phone: +91 80955 89888\nEmail: avatisafestorage07@gmail.com", 110, 73)
        var currentDate = new Date()

        var date = currentDate.getDate() + '-' + (currentDate.getMonth() + 1) + '-' + currentDate.getFullYear();
        doc.text(`date: ${date}`, 110, 86)
        doc.text(props.Name, 20, 73)
        doc.text(props.Name, 20, 73)
        doc.text(props.Email, 20, 78)
        doc.text(props.Phone, 20, 83)
        doc.setLineWidth(1.5)
        doc.line(20, 90, 200, 90)

        doc.text('YOUR STORAGE RENTAL', 20, 100)
        doc.text('YOUR STORAGE RENTAL', 20, 100)
        doc.text(`Rs.${storingTotal} / Month`, 170, 100)
        doc.text(`Rs.${storingTotal} / Month`, 170, 100)

        doc.setLineWidth(0.5)
        doc.line(20, 103, 200, 103)
        doc.text(25, 110, 'Item')
        doc.text(25, 110, 'Item')
        // doc.text(125, 110, 'Unit Cost')
        doc.text(155, 110, 'Qty')
        doc.text(175, 110, 'Total')
        doc.line(20, 115, 200, 115)
        doc.text(25, 123, 'Bike')
        doc.text(25, 129, 'Hatch Back Car')
        doc.text(25, 135, 'Sedan Car')
        doc.text(25, 141, 'SUV Car')

        // doc.text(125, 123, '500')
        // doc.text(125, 129, '2200')
        // doc.text(125, 135, '2700')
        // doc.text(125, 141, '3500')

        doc.text(160, 123, `${Number(Bikes)}`)
        doc.text(160, 129, `${Number(hatchBack)}`)
        doc.text(160, 135, `${Number(sedan)}`)
        doc.text(160, 141, `${Number(suv)}`)

        doc.text(176, 123, `${500 * Bikes}`)
        doc.text(176, 129, `${2200 * hatchBack}`)
        doc.text(176, 135, `${2700 * sedan}`)
        doc.text(176, 141, `${3500 * suv}`)

        doc.line(20, 150, 200, 150)
        doc.text(156, 160, `Sub total: ${storingSubtotal}`)
        doc.text(156, 167, `GST(${GST}%): ${storingGST}`)
        doc.text(156, 174, `Total: Rs.${storingTotal}`)
        doc.setFontSize(10)
        doc.text(20, 250, 'You find moving/handling estimation on the second page.')

        doc.text(20, 270, 'Notes: Quotation is based on items mentioned. Price might change based on actual number and \nvolume of items received.')
        doc.text(20, 278, 'Boxes for packing is returnable boxes')
        doc.text(20, 283, 'Retrievel of storage items has to be initiated 5 days prior')
        doc.text(20, 288, 'Insurance covers upto 2.5 lakhs (coverage depends upon list of items and actual price of items)')


        doc.addPage()
        doc.setFontSize(22)
        doc.text("ESTIMATION", 85, 20)
        doc.addImage(logo, 'PNG', 20, 40, 30, 20)
        doc.setFontSize(12)
        doc.text("Avati Safe Storage\n#05, Mallige Rd,\nKashivishwanatha Layout,\nKrishnarajapura, Bengaluru,\nKarnataka 560036", 110, 40)
        doc.text("Phone: +91 80955 89888\nEmail: avatisafestorage07@gmail.com", 110, 70)
        doc.text(`date: ${date}`, 110, 83)
        doc.text(props.Name, 20, 70)
        doc.text(props.Email, 20, 75)
        doc.text(props.Phone, 20, 83)
        doc.setLineWidth(1.5)
        doc.line(20, 90, 200, 90)

        doc.text('YOUR MOVING AND HANDLING ESTIMATION', 20, 100)

        doc.setLineWidth(0.5)
        doc.line(20, 103, 200, 103)
        if (NeedHelp === '1') {
            //     doc.text(25, 110, 'Item')
            //     doc.text(175, 110, 'Total')
            //     doc.line(20, 115, 200, 115)
            //     doc.text(25, 125, 'Professional Packing and Moving')
            //     doc.text(175, 125, `${PackAndMoveRate}`)
            doc.text(25, 115, `Moving and handling charges depends on the exact location and the distance\nto the warehouse and that can be done either by contacting us or our staff will be contacting\nyou soon.`)
        }
        else {
            doc.text(25, 115, `There is no moving/handling charges as you don't need help in moving/handling`)
        }

        doc.line(20, 135, 200, 135)
        // doc.text(156, 140, `Sub total: ${movingSubtotal} `)
        // if (movingDiscount !== 0) {
        //     doc.text(156, 147, `Discount(${offer}%): ${movingDiscount}`)
        //     doc.text(156, 154, `GST(5%): ${movingGST}`)
        //     doc.text(156, 168, `Total: Rs.${movingTotal}`)
        //     doc.text(156, 168, `Total: Rs.${movingTotal}`)
        //     doc.text(20, 185, 'Best regards,')
        //     doc.text(20, 185, 'Best regards,')
        //     doc.text(20, 192, 'Avati storage team')
        // }
        // else {
        //     doc.text(156, 147, `GST(5%): ${movingGST}`)
        //     doc.text(156, 161, `Total: ${movingTotal}`)
        //     doc.text(156, 161, `Total: ${movingTotal}`)
        doc.text(20, 175, 'Best regards,')
        doc.text(20, 175, 'Best regards,')
        doc.text(20, 185, 'Avati storage team')
        // }
        doc.setFontSize(10)
        doc.text(20, 225, 'Notes: Quotation is based on items mentioned. Price might change based on actual number and \nvolume of items received.')
        doc.text(20, 233, 'Boxes for packing is returnable boxes')
        doc.text(20, 238, 'Retrievel of storage items has to be initiated 5 days prior')
        doc.text(20, 243, 'Insurance covers upto 2.5 lakhs (coverage depends upon list of items and actual price of items)')

        doc.text(20, 250, 'TERMS AND CONDITIONS')
        doc.text(20, 260, '1. Storage of Food, Liquor, acid or any types of liquid (like ghee tin, oil etc) is totally prohibited. \n2. Payment in Favour of  Avati Safe Storage, Banglore. \n3. 30% payment of transportation/packing charges needs to be paid for the confirmation.\n4. Delivery of items are charged on actuals (from Avati Safe Storage warehouse to your location).\n5. Avati Safe Storage reserves the right to withdraw, amend or cancel a discount at any time.')

        var base = doc.output('datauristring');

        getQuote(base, storingTotal + movingTotal, props.Location, props.storage, props.Name, props.Email, props.Phone, '', '', '', '', '', '', '', '', '', Time, Duration, NeedHelp, '', '', Bikes, hatchBack, sedan, suv)
            .then(res => res.json()).then(res => {
                if (res.status === 'success') {
                    setSubmitting(false)
                    setcurrentItem('estimate')
                }
                else {
                    setSubmitting(false)
                }

            })
    }

    let downloadQuotation = () => {
        const randomNumber = Math.floor(Math.random() * 10000) + 1;
        doc.save(`${randomNumber}.pdf`);
        setcurrentItem('quantity')
        setBikes('0')
        setHatchBack('0')
        setSedan('0')
        setSUV('0')
        setSubmitting(false)
        setTime(null)
        setDuration(null)
        setNeedHelp(null)
        props.close()
    }

    return (
        <div>
            {currentItem === 'quantity' ?
                <div style={{ height: '83vh' }} className='d-lg-flex justify-content-center align-items-center'>
                    <div className='w-auto'>
                        <div className='form-query'>About how many automobiles do you need to store?</div>
                        <Form>
                            <Form.Group className='mt-3'>
                                <Form.Label className='form-query-label'>Number of bikes</Form.Label>
                                <Form.Control type='number' min="0" value={Bikes} onChange={(e) => setBikes(e.target.value)} />
                            </Form.Group>
                            <Form.Group className='mt-3'>
                                <Form.Label className='form-query-label'>Number of hatch back cars</Form.Label>
                                <Form.Control type='number' min="0" value={hatchBack} onChange={(e) => setHatchBack(e.target.value)} />
                            </Form.Group>
                            <Form.Group className='mt-3'>
                                <Form.Label className='form-query-label'>Number of sedan cars</Form.Label>
                                <Form.Control type='number' min="0" value={sedan} onChange={(e) => setSedan(e.target.value)} />
                            </Form.Group>
                            <Form.Group className='mt-3'>
                                <Form.Label className='form-query-label'>Number of SUV cars</Form.Label>
                                <Form.Control type='number' min="0" value={suv} onChange={(e) => setSUV(e.target.value)} />
                            </Form.Group>
                        </Form>
                        <div className='d-flex justify-content-end mt-5'>
                            <Button className='form-continue-button' onClick={() => setcurrentItem('time')}>Continue</Button>
                        </div>
                    </div>
                </div>
                :
                currentItem === 'time' ?
                    <div style={{ height: '83vh' }} className='d-flex flex-column justify-content-center align-items-center'>
                        <div className='form-query'>When you like to move into storage?</div>
                        <div className='mt-3'>
                            <Form.Check
                                id={1}
                                className='form-query-option p-2 ps-5'
                                type='radio'
                                name='time'
                                value='0'
                                checked={Time === '0'}
                                label='Next week' onChange={(e) => setTime(e.target.value)} />
                            <Form.Check
                                id={2}
                                className='form-query-option p-2 ps-5'
                                type='radio'
                                name='time'
                                value='1'
                                checked={Time === '1'}
                                label='Within a month' onChange={(e) => setTime(e.target.value)} />
                            <Form.Check
                                id={3}
                                className='form-query-option p-2 ps-5'
                                type='radio'
                                name='time'
                                value='2'
                                checked={Time === '2'}
                                label='I am flexible' onChange={(e) => setTime(e.target.value)} />
                        </div>
                        <div className='d-flex justify-content-end mt-5 w-25'>
                            <Button className='form-continue-button' onClick={() => setcurrentItem('duration')}>Continue</Button>
                        </div>
                    </div>
                    :
                    currentItem === 'duration' ?
                        <div style={{ height: '83vh' }} className='d-flex flex-column justify-content-center align-items-center'>
                            <div className='form-query'>How long are you planning to store</div>
                            <div className='mt-3'>
                                <Form.Check
                                    id={1}
                                    className='form-query-option p-2 ps-5'
                                    type='radio'
                                    name='duration'
                                    value='0'
                                    checked={Duration === '0'}
                                    label='Less than 1 month' onChange={(e) => setDuration(e.target.value)} />
                                <Form.Check
                                    id={2}
                                    className='form-query-option p-2 ps-5'
                                    type='radio'
                                    name='duration'
                                    value='1'
                                    checked={Duration === '1'}
                                    label='1 to 3 months' onChange={(e) => setDuration(e.target.value)} />
                                <Form.Check
                                    id={3}
                                    className='form-query-option p-2 ps-5'
                                    type='radio'
                                    name='duration'
                                    value='2'
                                    checked={Duration === '2'}
                                    label='3 to 12 months' onChange={(e) => setDuration(e.target.value)} />
                                <Form.Check
                                    id={4}
                                    className='form-query-option p-2 ps-5'
                                    type='radio'
                                    name='duration'
                                    value='3'
                                    checked={Duration === '3'}
                                    label='More than 12 months' onChange={(e) => setDuration(e.target.value)} />
                            </div>
                            <div className='d-flex justify-content-end mt-5 w-25'>
                                <Button className='form-continue-button' onClick={() => setcurrentItem('help')}>Continue</Button>
                            </div>
                        </div>
                        :
                        currentItem === 'help' ?
                            <div style={{ height: '83vh' }} className='d-flex flex-column justify-content-center align-items-center'>
                                <div className='form-query'>Do you need packing and moving help?</div>
                                <div className='mt-3'>
                                    <Form.Check
                                        id={1}
                                        className='form-query-option p-2 ps-5'
                                        type='radio'
                                        name='help'
                                        value='1'
                                        checked={NeedHelp === '1'}
                                        label='Yes, I need help' onChange={(e) => setNeedHelp(e.target.value)} />
                                    <Form.Check
                                        id={2}
                                        className='form-query-option p-2 ps-5'
                                        type='radio'
                                        name='help'
                                        value='0'
                                        checked={NeedHelp === '0'}
                                        label='No, I will do it myself' onChange={(e) => setNeedHelp(e.target.value)} />
                                </div>
                                <div className='d-flex justify-content-end w-25 mt-5'>
                                    <Button className='form-continue-button' onClick={getEstimatedCharge} disabled={submitting}>
                                        Continue
                                        {submitting ?
                                            <div className="spinner-border mx-2" style={{ color: '#E62115', height: '1rem', width: '1rem' }} role="status">
                                                <span className="sr-only"></span>
                                            </div> : ''}
                                    </Button>
                                </div>
                            </div>
                            :
                            <div style={{ height: '83vh' }} className='d-flex justify-content-center'>
                                <Card className='w-lg-50 border-0' style={{ backgroundColor: '#FFF', borderRadius: '25px' }}>
                                    <Card.Body className='d-flex flex-column align-items-center'>
                                        <Image src={thankyouIcon} className='mb-3' />
                                        <h2 className='fw-bold' style={{ color: '#3F3D56' }}>Thank you for getting in touch!</h2>
                                        <p style={{ color: '#3F3D56' }}>Our experts will reach you out soon.</p>
                                        <p align='center' className='fw-bold'>We have assigned dedicated relationship manager<br />
                                            to assist you with storage request.<br />
                                        </p>
                                        <p className='fw-bold'>Looking forward to serving you.</p>
                                        <p align='center' className='fw-bold'>Best Regards,<br />
                                            <span style={{ color: '#E62115' }}> Avati Safe Storage Team</span></p>
                                        <Button className='form-continue-button' onClick={downloadQuotation}>
                                            Download Your Quotation
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </div>
            }
        </div>

    )
}

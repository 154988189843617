import React from 'react'
import { Col, Image, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { automobileStorageImage, businessStorageImage, householdStorageImage, luggageStorageImage, officeStorageImage, packersAndMoversImage } from '../../assets'
import { AnimationOnScroll } from 'react-animation-on-scroll';

export default function StorageServicesSection() {
    let navigate = useNavigate()
    return (
        <div className='storage-service-section' id='storage-service'>
            <h2 style={{ fontWeight: '800', fontSize: '2em' }}>Storage Services</h2>
            <div className='w-100 d-flex justify-content-center'>
                <AnimationOnScroll duration={1} animateIn="animate__zoomIn" className='storage-service-section-underline'  style={{ borderTop: '3px solid #000',width:'30%' }}>
                </AnimationOnScroll>
            </div>
            <Row className='mx-0 flex-wrap' style={{ marginTop: '73px' }}>
                <Col className='col-md-4 col-6 position-relative' style={{ marginBottom: '37px', cursor: 'pointer' }}
                    onClick={() => navigate('/banglore/household-storage')}>
                    <Image src={householdStorageImage} className='storage-service-image' />
                    <AnimationOnScroll duration={2} delay={100} animateIn="animate__slideInUp" className='btn-primary btn storage-service-button position-absolute'>
                        <AnimationOnScroll duration={2} delay={150} animateIn="animate__slideInUp">HOUSEHOLD STORAGE
                        </AnimationOnScroll>
                    </AnimationOnScroll>
                </Col>
                <Col className='col-md-4 col-6 position-relative' style={{ marginBottom: '37px', cursor: 'pointer' }}
                    onClick={() => navigate('/banglore/business-storage', { state: { image: businessStorageImage, title: 'BUSINESS STORAGE' } })}>
                    <Image src={businessStorageImage} className='storage-service-image' />
                    <AnimationOnScroll duration={2} delay={100} animateIn="animate__slideInUp" className='btn btn-primary storage-service-button position-absolute'>
                        <AnimationOnScroll duration={2} delay={150} animateIn="animate__slideInUp">
                            BUSINESS STORAGE
                        </AnimationOnScroll>
                    </AnimationOnScroll>
                </Col>
                <Col className='col-md-4 col-6 position-relative' style={{ marginBottom: '37px', cursor: 'pointer' }}
                    onClick={() => navigate('/banglore/office-storage', { state: { image: officeStorageImage, title: 'OFFICE STORAGE' } })}>
                    <Image src={officeStorageImage} className='storage-service-image' />
                    <AnimationOnScroll duration={2} delay={100} animateIn="animate__slideInUp" className='btn btn-primary storage-service-button position-absolute'>
                        <AnimationOnScroll duration={2} delay={150} animateIn="animate__slideInUp">
                            OFFICE STORAGE
                        </AnimationOnScroll>
                    </AnimationOnScroll>
                </Col>
                <Col className='col-md-4 col-6 position-relative' style={{ marginBottom: '37px', cursor: 'pointer' }}
                    onClick={() => navigate('/banglore/luggage-storage', { state: { image: luggageStorageImage, title: 'LUGGAGE STORAGE' } })}>
                    <Image src={luggageStorageImage} className='storage-service-image' />
                    <AnimationOnScroll duration={2} delay={100} animateIn="animate__slideInUp" className='btn btn-primary storage-service-button position-absolute'>
                        <AnimationOnScroll duration={2} delay={150} animateIn="animate__slideInUp">
                            LUGGAGE STORAGE
                        </AnimationOnScroll>
                    </AnimationOnScroll>
                </Col>
                <Col className='col-md-4 col-6 position-relative' style={{ marginBottom: '37px', cursor: 'pointer' }}
                    onClick={() => navigate('/banglore/automobile-storage', { state: { image: automobileStorageImage, title: 'AUTOMOBILE STORAGE' } })}>
                    <Image src={automobileStorageImage} className='storage-service-image' />
                    <AnimationOnScroll duration={2} delay={100} animateIn="animate__slideInUp" className='btn btn-primary storage-service-button position-absolute'>
                        <AnimationOnScroll duration={2} delay={150} animateIn="animate__slideInUp">
                            AUTOMOBILE STORAGE
                        </AnimationOnScroll>
                    </AnimationOnScroll>
                </Col>
                <Col className='col-md-4 col-6 position-relative' style={{ marginBottom: '37px', cursor: 'pointer' }}
                    onClick={() => navigate('/banglore/packers-and-movers', { state: { image: packersAndMoversImage, title: 'PACKERS AND MOVERS' } })}>
                    <Image src={packersAndMoversImage} className='storage-service-image' />
                    <AnimationOnScroll duration={2} delay={100} animateIn="animate__slideInUp" className='btn btn-primary storage-service-button position-absolute'>
                        <AnimationOnScroll duration={2} delay={150} animateIn="animate__slideInUp">
                            PACKERS AND MOVERS
                        </AnimationOnScroll>
                    </AnimationOnScroll>
                </Col>
            </Row >
        </div >
    )
}

import React from "react";
import { Button, Container, Image, Nav, Navbar } from "react-bootstrap";
import { logo } from "../../assets";
import "./NavbarComponent.css";
import { useNavigate } from "react-router-dom";

export default function NavbarComponent({ top, showContactUs }) {
  const navigate = useNavigate();

  return (
    <Navbar
      className="navbar-component"
      expand="lg"
      id="navbar-component"
      style={{ top: top ? top : "" }}
    >
      <Container fluid className="navbar-container">
        <Navbar.Brand href="/">
          <Image src={logo} className="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="justify-content-end" id="basic-navbar-nav">
          <Nav className="m-0 align-items-lg-center align-items-end">
            {showContactUs && (
              <Button
                className="mx-2 mt-lg-0 mb-lg-0 m-2 contact-button"
                onClick={() => navigate("/contactus")}
              >
                Contact us
              </Button>
            )}
            <Button
              className="sign-in-button m-lg-0 m-2"
              onClick={() => navigate("/get-quote")}
            >
              Get a Quote
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./ConfirmCloseModalComponent.css";

export default function ConfirmCloseModalComponent(props) {
  const navigate = useNavigate();
  return (
    <Modal
      className="confirm-modal"
      show={props.openModal}
      onHide={() => props.closeModal}
    >
      <Modal.Body>
        <Row className="m-0 gap-md-0 gap-5">
          <Col className="col-md-6 col-12 ">
            <h3 className="fw-bold">Want help over the phone</h3>
            <p>
              Give us a call and we will help you with the storage requirement!
            </p>
            <div>
              <Button
                className="w-100 mt-3 fw-bold"
                style={{
                  backgroundColor: "#FFF",
                  border: "2px solid #E62115",
                  borderRadius: "8px",
                  color: "#E62115",
                }}
              >
                <a
                  href="tel:+91 80955 89888"
                  style={{ color: "#E62115", textDecoration: "none" }}
                >
                  CALL +91 80955 89888
                </a>
              </Button>
              <Button
                className="w-100 mt-3 fw-bold"
                style={{
                  backgroundColor: "#FFF",
                  border: "2px solid #E62115",
                  borderRadius: "8px",
                  color: "#E62115",
                }}
                onClick={props.cancelProject}
              >
                CANCEL PROJECT
              </Button>
              <Button
                className="w-100 mt-3 fw-bold"
                style={{
                  backgroundColor: "#E62115",
                  border: "2px solid #E62115",
                  borderRadius: "8px",
                }}
                onClick={props.closeModal}
              >
                RETURN TO PROJECT
              </Button>
            </div>
          </Col>
          <Col className="col-md-6 col-12 d-flex flex-column justify-content-around">
            <h1 className="bannerTextWrapper">
              Don't leave yet,
              <br /> get upto 30% off !
            </h1>
            <div className="d-flex justify-content-center">
              <Button
                className="bannerButton mt-3 fw-bold"
                onClick={() => navigate("/contactus")}
              >
                Get a Callback
              </Button>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

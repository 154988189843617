import React from 'react'
import { Col, Image, Row } from 'react-bootstrap'
import { blogImage, blogImage1, blogImage3 } from '../../assets'
import { AnimationOnScroll } from 'react-animation-on-scroll'

export default function BlogsSection() {
    return (
        <div className='blogs-section'>
            <h2 style={{ fontWeight: '800' }}>Blogs</h2>
            <Row className=''>
                <Col className='col-lg-4 col-12 position-relative'>
                    <a href='https://fushionworld.com/advantages-of-storage-as-a-service/' target={'_blank'} rel='noreferrer'
                        style={{ color: '#000' }}>
                        <Image src={blogImage1} className='blog-image' />
                        <AnimationOnScroll duration={2} animateIn="animate__slideInUp" className='position-absolute blog-details'>
                            Advantages of Storage as a service
                        </AnimationOnScroll>
                    </a>
                </Col>
                <Col className='col-lg-4 col-12 position-relative mt-5 mt-lg-0'>
                    <a href='https://techplanet.today/post/9-factors-to-consider-when-choosing-a-storage-unit' target={'_blank'} rel='noreferrer'
                        style={{ color: '#000' }}>
                        <Image src={blogImage} className='blog-image' />
                        <AnimationOnScroll duration={2} animateIn="animate__slideInUp" className='position-absolute blog-details'>
                            9 Factors to Consider When Choosing a Storage Unit
                        </AnimationOnScroll>
                    </a>
                </Col>
                <Col className='col-lg-4 col-12 position-relative  mt-5 mt-lg-0'>
                    <a href='https://medium.com/@storagefacilityinbangalore/best-storage-facilities-in-bangalore-282ea49a011e' target={'_blank'} rel='noreferrer'
                        style={{ color: '#000' }}>
                        <Image src={blogImage3} className='blog-image' />
                        <AnimationOnScroll duration={2} animateIn="animate__slideInUp" className='position-absolute blog-details'>
                            Best Storage Facilities in Bangalore
                        </AnimationOnScroll>
                    </a>
                </Col>
            </Row>
        </div>
    )
}

import React, { Component } from "react";
import { Card } from "react-bootstrap";
import { FooterComponent, NavbarComponent } from "../../SharedComponents";
import "./PaymentDetailsView.css";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";

export default class PaymentDetailsView extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef(); // Create a ref object
  }
  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    window.scrollTo(500, 0);
  }
  render() {
    return (
      <div>
        <Helmet>
          <title>
            Payment Details - Avati safe storage - Storage Places in Bangalore
          </title>
          <meta
            name="description"
            content="Avatisafestorage.com takes great pride in our services. Here we mentioned all payment details with upi id, now you can pay easily through upi id also."
          />
        </Helmet>
        <NavbarComponent />
        <div className="payment-details-view d-flex justify-content-center flex-column align-items-center">
          <h1 style={{ fontWeight: "800", color: "var(--primary-color)" }}>
            Payment Details
          </h1>
          <Card className="mt-5 w-lg-50 w-md-75 w-100">
            <Card.Header>
              <Card.Title style={{ fontWeight: "800" }}>
                Bank Details
              </Card.Title>
            </Card.Header>
            <Card.Body className="p-5">
              <Card.Text className="d-flex justify-content-center">
                <div
                  style={{
                    color: "rgba(0,0,0,0.7)",
                    width: "120px",
                    textAlign: "start",
                  }}
                >
                  Name
                </div>
                :
                <div
                  style={{
                    fontWeight: "500",
                    width: "200px",
                    textAlign: "start",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;Avati Safe Storage
                </div>
              </Card.Text>
              <Card.Text className="d-flex justify-content-center">
                <div
                  style={{
                    color: "rgba(0,0,0,0.7)",
                    width: "120px",
                    textAlign: "start",
                  }}
                >
                  Acc. No.
                </div>
                :
                <div
                  style={{
                    fontWeight: "500",
                    width: "200px",
                    textAlign: "start",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;34960200000473
                </div>
              </Card.Text>
              <Card.Text className="d-flex justify-content-center">
                <div
                  style={{
                    color: "rgba(0,0,0,0.7)",
                    width: "120px",
                    textAlign: "start",
                  }}
                >
                  IFSC Code
                </div>
                :
                <div
                  style={{
                    fontWeight: "500",
                    width: "200px",
                    textAlign: "start",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;BARB0KRPURM
                </div>
              </Card.Text>
            </Card.Body>
            <Card.Header className="mt-4">
              <Card.Title style={{ fontWeight: "800" }}>UPI ID</Card.Title>
            </Card.Header>
            <Card.Body className="p-5">
              <Card.Text>avatisafestorage07@okaxis</Card.Text>
            </Card.Body>
          </Card>
        </div>
        <FooterComponent />
      </div>
    );
  }
}

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getBlogDetails } from "../../api";
import { Helmet } from "react-helmet";
import { NavbarComponent, FooterComponent } from "../../SharedComponents";
import "./BlogSectionView.css";
import he from "he"; // Import the 'he' library

function BlogDetailView() {
  const { title } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;

    async function fetchBlogDetails() {
      try {
        console.log("Fetching blog details for title:", title);
        const response = await getBlogDetails(title);
        console.log("API response:", response);

        if (response.status === 200) {
          const data = await response.json();
          console.log("Blog data:", data);

          if (data && data.length > 0) {
            if (isMounted) {
              setBlog(data[0]); // Assuming data is an array, set the first item
              setLoading(false);
            }
          } else {
            console.error("No blog data found.");
            if (isMounted) {
              setLoading(false);
            }
          }
        } else {
          console.error("Failed to fetch blog content. Status code:", response.status);
          if (isMounted) {
            setLoading(false);
          }
        }
      } catch (error) {
        console.error("Error fetching blog content:", error);
        if (isMounted) {
          setLoading(false);
        }
      }
    }

    fetchBlogDetails();

    return () => {
      isMounted = false;
    };
  }, [title]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!blog) {
    return <div>Failed to load blog content.</div>;
  }

  const decodedDescription = he.decode(blog.description);

  return (
    <div>
      <Helmet>
        <title>{blog.title} - Avati Safe Storage - Storage Places in Bangalore</title>
        <meta name="description" content={decodedDescription} />
      </Helmet>
      <NavbarComponent />
      <div className="blog-details-container" style={{ textAlign: "left", display: "flex", flexDirection: "column", alignItems: "center" }}>
        <h1 className="blog-title" style={{ textAlign: "center" }}>{blog.title}</h1>
        <img
          src={`https://www.avatisafestorage.com/admin/uploads/blog/banner/${blog.banner}`}
          alt={`Banner for ${blog.title}`}
          style={{ maxWidth: "100%", height: "auto" }}
        />
        
         <div dangerouslySetInnerHTML={{ __html: decodedDescription }} />
        {/* Display other blog post details as needed */}
      </div>
      <FooterComponent />
    </div>
  );
}

export default BlogDetailView;

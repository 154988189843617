import React from "react";
import {  Container, Image, Nav, Navbar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { logo } from "../../assets";

export default function Navbar2Component() {
    const navigate = useNavigate()
  return (
    <Navbar className="navbar2-component" expand="lg" id="navbar-component">
      <Container fluid className="navbar-container">
        <Navbar.Brand href="/">
          {/* <Image src={logo} className="logo" /> */}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="justify-content-end" id="basic-navbar-nav">
          <Nav className="m-0 align-items-lg-center align-items-end">
            <Nav.Link
              id="service-link"
              className={`navbar-link px-4`}
                onClick={()=> navigate('/banglore/household-storage')}
            >
              Household storage
            </Nav.Link>
            <Nav.Link
              id="process-link"
              className={`navbar-link px-4`}
              onClick={()=> navigate('/banglore/business-storage')}

            >
              Business storage
            </Nav.Link>
            <Nav.Link
              id="choose-link"
              className={`navbar-link px-4`}
              onClick={()=> navigate('/banglore/office-storage')}

            >
              Office storage
            </Nav.Link>
            <Nav.Link
              id="payment-link"
              className={`navbar-link px-4`}
              onClick={()=> navigate('/banglore/luggage-storage')}

            >
              Luggage storage
            </Nav.Link>
            <Nav.Link
              id="payment-link"
              className={`navbar-link px-4`}
              onClick={()=> navigate('/banglore/automobile-storage')}

            >
              Automobile storage
            </Nav.Link>
            {/* <Button
              className="sign-in-button m-lg-0 m-2"
              //   onClick={() => navigate("get-quote")}
            >
              Get a Quote
            </Button> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

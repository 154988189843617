import LandingPageView from "./Views/LandingPageView/LandingPageView";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import HouseholdStorageView from "./Views/StorageDetailsView/HouseholdStorageView";
import WhyChooseUsView from "./Views/WhyChooseUsView/WhyChooseUsView";
import PaymentDetailsView from "./Views/PaymentDetailsView/PaymentDetailsView";
import BlogSectionView from "./Views/BlogSectionView/BlogSectionView";
import BlogDetailView from "./Views/BlogSectionView/BlogDetailView";
import { FormModalComponent } from "./SharedComponents";
import BusinessStorageView from "./Views/StorageDetailsView/BusinessStorageView";
import LuggageStorageView from "./Views/StorageDetailsView/LuggageStorageView";
import AutomobileStorageView from "./Views/StorageDetailsView/AutomobileStorageView";
import OfficeStorageView from "./Views/StorageDetailsView/OfficeStorageView";
import PackersAndMovers from "./Views/StorageDetailsView/PackersAndMovers";
import FreeQuoteSection from "./Views/ContactUsView/ContactUsView";
import ReactGA from "react-ga";
import ThankYouView from "./Views/ThankYouView/ThankYouView";
import PrivacyPolicy from "./Views/PrivacyPolicyView/PrivacyPolicy";
import Testingcodeview from "./Views/Testingcodeview/testing-code"
import LandingPage2 from "./Views/LandingPage2/LandingPage2";

ReactGA.initialize("UA-250479411-1");

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<LandingPageView />} />
        <Route path="/storage-services" element={<LandingPage2 />} />
        <Route path="/about-us" element={<WhyChooseUsView />} />
        <Route path="/payment-details" element={<PaymentDetailsView />} />
        <Route path="/blog-section" element={<BlogSectionView />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/testing-code" element={<Testingcodeview />} />
        <Route
          path="/banglore/household-storage"
          element={<HouseholdStorageView />}
        />
        <Route
          path="/banglore/business-storage"
          element={<BusinessStorageView />}
        />
        <Route
          path="/banglore/luggage-storage"
          element={<LuggageStorageView />}
        />
        <Route
          path="/banglore/automobile-storage"
          element={<AutomobileStorageView />}
        />
        <Route
          path="/banglore/office-storage"
          element={<OfficeStorageView />}
        />
        <Route
          path="/banglore/packers-and-movers"
          element={<PackersAndMovers />}
        />
         <Route path="/blog/:title" element={<BlogDetailView />} />

        <Route path="/get-quote" element={<FormModalComponent />} />
        <Route path="/contactus" element={<FreeQuoteSection />} />
        <Route path="/thank-you" element={<ThankYouView />} />
      </Routes>
    </div>
  );
}

export default App;

import { Field, Formik } from "formik";
import React, { useEffect } from "react";
import { callIcon, ellipse, locationIcon, messageIcon } from "../../assets";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import { contactUs } from "../../api";
import {
  GetAfreeQuoteComponent,
  NavbarComponent,
} from "../../SharedComponents";
import ReactGA from "react-ga";
import { useNavigate } from "react-router-dom";

export default function FreeQuoteSection() {
  let navigate = useNavigate();
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  let getFreeQute = (values, { resetForm }) => {
    console.log(values)
    contactUs(values)
      .then((res) => res.json())
      .then((res) => {
        console.log(res)
        if (res.status === "success") {
          resetForm({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            message: "",
          });
          navigate("/thank-you");
        }
      }).catch((err)=> {
        console.log(err)
      });
  };
  return (
    <div>
      <NavbarComponent />
      <div className="free-quote-section">
        <h1 style={{ fontWeight: "600" }}>Get a Free Quote</h1>
        <Row
          className="mx-0 justify-content-between"
          style={{ marginTop: "48px" }}
        >
          <Col md={6}>
            <Row style={{ marginTop: "40px" }}>
              <div className="w-auto position-relative ps-0">
                <Image src={ellipse} className="elipse-size" />
                <Image src={callIcon} className="position-absolute call-icon" />
              </div>
              <div className="w-auto pe-0">
                <div
                  className="text-start"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "1.5em",
                    fontWeight: "600",
                  }}
                >
                  {" "}
                  Give us a Call
                </div>
                <div
                  className="text-start"
                  style={{ fontWeight: "600", fontSize: "0.8em" }}
                >
                  +91 80955 89888
                </div>
              </div>
            </Row>
            <Row style={{ marginTop: "40px" }}>
              <div className="w-auto position-relative ps-0">
                <Image src={ellipse} className="elipse-size" />
                <Image
                  src={locationIcon}
                  className="position-absolute location-icon"
                />
              </div>
              <div className="w-auto pe-0">
                <div
                  className="text-start"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "1.5em",
                    fontWeight: "600",
                  }}
                >
                  Address
                </div>
                <div
                  className="text-start"
                  style={{ fontWeight: "600", fontSize: "0.8em" }}
                >
                  Avati Safe Storage <br />
                  #429/5, M.R.B. Farms,
                  <br />
                  Nakasha Road, N.R.I. layout,
                  <br />
                  Opp. HDFC Bank, Kalkere, Horamavu Post,
                  <br />
                  Bangalore - 560043
                  <br />
                  Bengaluru, State of Karnataka 560043
                </div>
              </div>
            </Row>
            <Row style={{ marginTop: "40px", marginBottom: "40px" }}>
              <div className="w-auto position-relative ps-0">
                <Image src={ellipse} className="elipse-size" />
                <Image
                  src={messageIcon}
                  className="position-absolute message-icon"
                />
              </div>
              <div className="w-auto pe-0">
                <div
                  className="text-start"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "1.5em",
                    fontWeight: "600",
                  }}
                >
                  Send Us a Message
                </div>
                <div
                  className="text-start"
                  style={{ fontWeight: "600", fontSize: "0.8em" }}
                >
                  contact@avatisafestorage.com
                </div>
              </div>
            </Row>
          </Col>
          <Col md={6} className="free-quote-form-bg position-relative">
            <GetAfreeQuoteComponent getFreeQute={getFreeQute} />
          </Col>
        </Row>
      </div>
    </div>
  );
}
